import { alpha } from "@mui/material";
import theme from "../../theme";


export const borderedSectionStyles = {
    border: '1px solid #cbcbcb',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1)
}

export const formDiffGreenColor = '#90EE90';
export const formDiffRedColor = '#ffcccb';


export const stickySubheaderStyles = {
    position: 'sticky',
    zIndex: 10,
    top: '0px',
    background: alpha(theme.palette.primary.backgroundLight!, 0.6),
    borderBottom: `1px solid #c4c4c4`,
    backdropFilter: 'blur(8px)',
    WebkitBackdropFilter: 'blur(8px)'

}