/**
 * Feature flag management utility for ResumeFactoryAI
 * Uses localStorage to persist feature flag states
 */

export interface FeatureFlags {
  simplifiedResumeView: boolean;
}

const FEATURE_FLAG_PREFIX = 'resume_factory_feature_';

/**
 * Get the current state of a feature flag
 */
export const getFeatureFlag = (key: keyof FeatureFlags): boolean => {
  const value = localStorage.getItem(`${FEATURE_FLAG_PREFIX}${key}`);
  // Default simplifiedResumeView to true if not set
  if (key === 'simplifiedResumeView' && value === null) {
    return true;
  }
  return value === 'true';
};

/**
 * Set the state of a feature flag
 */
export const setFeatureFlag = (key: keyof FeatureFlags, value: boolean): void => {
  localStorage.setItem(`${FEATURE_FLAG_PREFIX}${key}`, value.toString());
};

/**
 * Reset a feature flag to its default state
 */
export const resetFeatureFlag = (key: keyof FeatureFlags): void => {
  localStorage.removeItem(`${FEATURE_FLAG_PREFIX}${key}`);
}; 