import { 
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
  CognitoUserSession,
  ICognitoUserSessionData,
  CognitoIdToken,
  CognitoAccessToken
} from 'amazon-cognito-identity-js';
import UserPool from '../../Configs/UserPool';
import { httpOnCheckAuthProvider } from '../Http/Auth';
import { getStoredTokens } from './Session';
import { testRefreshFlow } from './SessionUtils';

// Storage key prefix for all Cognito-related items
const STORAGE_KEY_PREFIX = 'CognitoIdentityServiceProvider';
const CLIENT_ID = process.env.REACT_APP_USER_POOL_CLIENT_ID || '';

interface CognitoError extends Error {
  code?: string;
}

declare global {
  interface Window {
    google?: {
      accounts?: {
        id?: {
          initialize: (config: any) => void;
          prompt: (callback: (notification: any) => void) => void;
          disableAutoSelect: () => void;
          cancel: () => void;
        }
      }
    }
  }
}

export const awsOnUserSignup = (email: string, password: string, name: string): Promise<any> => {
  return new Promise((resolve, reject): void => {
    const attributeList: CognitoUserAttribute[] = [];
    const userNameObject: { Name: string; Value: string } = { Name: "nickname", Value: name };
    const userNameAttribute: CognitoUserAttribute = new CognitoUserAttribute(userNameObject);
    attributeList.push(userNameAttribute);

    UserPool.signUp(email, password, attributeList, [], (err, data: any) => {
      if (err) {
        reject(err);
      } else {
        // Notify user to confirm their email
        resolve({ message: 'Signup successful. Please check your email to confirm your account.' });
      }
    });
  });
};

export const clearAllAuthState = () => {
  // Clear Cognito state first
  const user = UserPool.getCurrentUser();
  if (user) {
    user.signOut();
  }
  
  // Clear all auth-related localStorage items
  Object.keys(localStorage).forEach(key => {
    // Remove all Cognito-related items regardless of format
    if (key.startsWith('CognitoIdentityServiceProvider')) {
      localStorage.removeItem(key);
    }
    // Remove other auth-related items
    if ([
      'cognitoAccessToken',
      'userId',
      'impersonatedId',
      'authProvider',
      'lastAuthUser',
      'amplify-signin-with-hostedUI'
    ].includes(key) || key.startsWith('persist:')) {
      localStorage.removeItem(key);
    }
  });

  // Clear sessionStorage as well
  sessionStorage.clear();

  // Clear Google auth state if exists
  const googleAccounts = window.google?.accounts?.id;
  if (googleAccounts) {
    googleAccounts.disableAutoSelect();
    googleAccounts.cancel();
  }

  // Clear any cookies for our domain
  document.cookie.split(";").forEach(function(c) { 
    document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
}

// Helper to store auth state consistently
export const storeAuthState = (cognitoId: string, accessToken: string, idToken: string, authProvider: 'email' | 'google') => {
  // Store tokens in Cognito format
  localStorage.setItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.LastAuthUser`, cognitoId);
  localStorage.setItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${cognitoId}.accessToken`, accessToken);
  localStorage.setItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${cognitoId}.idToken`, idToken);
  localStorage.setItem('authProvider', authProvider);
}

export const awsOnUserSignIn = (email: string, password: string): any => {
  return new Promise(async (resolve, reject) => {
    try {
      // Clear any existing auth state before attempting login
      clearAllAuthState();

      // Check auth provider first
      const authProviderResponse = await httpOnCheckAuthProvider(email);
      if (authProviderResponse.data.auth_provider === 'google') {
        reject({
          name: 'GoogleAuthRequired',
          message: 'This account uses Google Sign-In. Please sign in with Google instead.',
          showGoogleOption: true
        });
        return;
      }

      const user = new CognitoUser({
        Username: email,
        Pool: UserPool
      });

      const credentials = new AuthenticationDetails({
        Username: email,
        Password: password
      });

      user.authenticateUser(credentials, {
        onSuccess: async (data) => {
          const accessToken = data.getAccessToken().getJwtToken();
          const idToken = data.getIdToken().getJwtToken();
          const payload = data.getIdToken().payload;
          const cognitoId = payload['cognito:username'];
          const name = payload['nickname'] || payload['name'] || '';
          const email = payload['email'];
          const groups = payload?.["cognito:groups"] || [];
          const isAdmin = groups.includes('ADMIN');
          const role = isAdmin ? 'ADMIN' : 'USER';

          // Store auth state consistently
          storeAuthState(cognitoId, accessToken, idToken, 'email');
          
          // Test refresh token flow immediately after login
          console.log('🧪 Testing refresh token flow immediately after login...');
          setTimeout(() => {
            testRefreshFlow(getStoredTokens, localStorage.setItem.bind(localStorage))
              .then(success => {
                console.log(`🔄 Immediate refresh token test ${success ? 'succeeded' : 'failed'}`);
              })
              .catch(error => {
                console.error('❌ Error during immediate refresh token test:', error);
              });
          }, 2000); // Wait 2 seconds to ensure tokens are properly stored
          
          resolve({ 
            id: cognitoId, 
            email, 
            name, 
            role, 
            isAdmin, 
            image: '', 
            accessToken: accessToken,
            authProvider: 'email'
          });
        },
        onFailure: (err) => {
          reject(err);
        },
        newPasswordRequired: () => {
          // Return special object to indicate password reset required
          resolve({ 
            newPasswordRequired: true,
            email,
            message: 'You need to set a new password before continuing.'
          });
        }
      });
    } catch (error: any) {
      reject(error);
    }
  });
};

export const awsOnUserSignOut = (): any => {
  return new Promise((resolve, reject) => {
    try {
      clearAllAuthState();
      resolve('success');
    } catch (error) {
      reject(error);
    }
  });
}

export const awsOnChangePassword = (email: string, oldPassword: string, newPassword: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool
    });

    // Authenticate the user with the old password first
    user.authenticateUser(
      new AuthenticationDetails({
        Username: email,
        Password: oldPassword
      }),
      {
        onSuccess: () => {
          // If authentication is successful, proceed to change the password
          user.changePassword(oldPassword, newPassword, (err, result) => {
            if (err) {
              reject(err);
            } else {
              resolve({ message: 'Password change successful.' });
            }
          });
        },
        onFailure: (err) => {
          reject(err);
        }
      }
    );
  });
};

export interface IUser {
  id: string,
  isLoggedIn: boolean,
  apiUserId: string,
  accessToken: string,
  membershipType?: string,
  membershipActiveUntil?: string,
}

export const hasValidSession = (user: IUser) => {
  const awsStoredUser = UserPool.getCurrentUser();
  if (!awsStoredUser) {
    return false;
  }

  // Get tokens from localStorage using Cognito's format
  const userId = localStorage.getItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.LastAuthUser`);
  if (!userId) {
    return false;
  }

  const accessToken = localStorage.getItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${userId}.accessToken`);
  const idToken = localStorage.getItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${userId}.idToken`);
  if (!accessToken || !idToken) {
    return false;
  }

  const cognitoIdToken = new CognitoIdToken({ IdToken: idToken });
  const cognitoAccessToken = new CognitoAccessToken({ AccessToken: accessToken });
  const sessionData: ICognitoUserSessionData = {
    IdToken: cognitoIdToken,
    AccessToken: cognitoAccessToken,
  }
  const cognitoUserSession = new CognitoUserSession(sessionData);
  const isValid = cognitoUserSession.isValid();
  console.log('hasValidSession - ', isValid);
  return user.isLoggedIn && isValid;
}

export const hasExpiredSession = (user: IUser) => {
  return user.isLoggedIn && !hasValidSession(user);
}

// Add new function for completing new password challenge
export const awsOnCompleteNewPassword = (email: string, newPassword: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool
    });

    // Get the current session which should be in the NEW_PASSWORD_REQUIRED state
    user.getSession((err: any, session: any) => {
      if (err) {
        reject(err);
        return;
      }

      // Complete the new password challenge
      user.completeNewPasswordChallenge(newPassword, {}, {
        onSuccess: (result) => {
          const accessToken = result.getAccessToken().getJwtToken();
          const idToken = result.getIdToken().getJwtToken();
          const payload = result.getIdToken().payload;
          const cognitoId = payload['cognito:username'];
          const name = payload['nickname'] || payload['name'] || '';
          const email = payload['email'];
          const groups = payload?.["cognito:groups"] || [];
          const isAdmin = groups.includes('ADMIN');
          const role = isAdmin ? 'ADMIN' : 'USER';

          // Store auth state consistently
          storeAuthState(cognitoId, accessToken, idToken, 'email');
          
          resolve({ 
            id: cognitoId, 
            email, 
            name, 
            role, 
            isAdmin, 
            image: '', 
            accessToken: accessToken,
            authProvider: 'email'
          });
        },
        onFailure: (err) => {
          reject(err);
        }
      });
    });
  });
};

export const awsOnForgotPassword = (email: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool
    });

    user.forgotPassword({
      onSuccess: () => {
        resolve({
          message: 'Password reset code has been sent to your email.'
        });
      },
      onFailure: (err) => {
        reject(err);
      },
      inputVerificationCode: (data) => {
        resolve({
          message: 'Password reset code has been sent to your email.',
          email,
          data
        });
      }
    });
  });
};

export const awsOnConfirmNewPassword = (email: string, code: string, newPassword: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool
    });

    user.confirmPassword(code, newPassword, {
      onSuccess: () => {
        resolve({
          message: 'Password has been reset successfully.'
        });
      },
      onFailure: (err) => {
        reject(err);
      }
    });
  });
};

/**
 * Confirms a password reset request with the verification code and new password
 */
export const awsOnConfirmForgotPassword = async (
  email: string,
  code: string,
  newPassword: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool
    });

    user.confirmPassword(code, newPassword, {
      onSuccess: () => {
        resolve();
      },
      onFailure: (err: CognitoError) => {
        if (err.code === 'CodeMismatchException') {
          reject(new Error('Invalid verification code. Please try again.'));
        } else if (err.code === 'ExpiredCodeException') {
          reject(new Error('Verification code has expired. Please request a new one.'));
        } else if (err.code === 'LimitExceededException') {
          reject(new Error('Too many attempts. Please try again later.'));
        } else if (err.code === 'InvalidPasswordException') {
          reject(new Error('Password does not meet requirements. Please choose a stronger password.'));
        } else {
          reject(err);
        }
      }
    });
  });
};

export const awsOnInitialPasswordSetup = (email: string, password: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool
    });

    // First trigger forgot password to get verification code
    user.forgotPassword({
      onSuccess: () => {
        resolve({ 
          needsVerification: true,
          message: 'Please check your email for a verification code.'
        });
      },
      onFailure: (err) => {
        reject(err);
      }
    });
  });
};

export const awsOnCompleteInitialPasswordSetup = (
  email: string, 
  verificationCode: string,
  newPassword: string
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool
    });

    user.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => {
        resolve({ message: 'Password set successfully.' });
      },
      onFailure: (err) => {
        reject(err);
      }
    });
  });
};