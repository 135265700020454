import React from "react";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, styled } from '@mui/material';
import { Box, Theme } from "@mui/material";
import useUser from "../Hooks/useUser";
import { useDispatch } from "react-redux";
import { onAlertShowAction } from "../Redux";
import { httpPollTailoredComparisons, httpOnTriggerTailoredComparisons, formatErrorMessageHtml, httpOnGetTailored } from "../Services/Http";
import ComparisonScoresView, { ComparisonScoresSideBySideView } from "./ComparisonScoresView";
import { //BothOfComparisons, 
  Comparison } from "../Services/Http/interface";
import { Close, OpenInFull } from "@mui/icons-material";

const PREFIX = 'ComparisonScoresModal';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  box: `${PREFIX}-box`,
  dragNdrop: `${PREFIX}-dragNdrop`,
  modalRoot: `${PREFIX}-modalRoot`,
  textInput: `${PREFIX}-textInput`,
  iconContainer: `${PREFIX}-iconContainer`,
  error: `${PREFIX}-error`,
  button: `${PREFIX}-button`,
  browseFilesButton: `${PREFIX}-browseFilesButton`,
  title: `${PREFIX}-title`
};

const Root = styled(Box)(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    
  },

  [`& .${classes.container}`]: {
    position: "absolute",
    borderRadius: 7,
    width: "50%",
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    margin: theme.spacing(2),
    backgroundColor: theme.colors.white,

  },

  [`& .${classes.box}`]: {
    alignItems: "center",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.dragNdrop}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(3),
    borderRadius: 7,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: theme.palette.secondary.main,
  },

  [`& .${classes.modalRoot}`]: {
    width: "100%",
  },

  [`& .${classes.textInput}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    textAlign: "center",
    marginTop: theme.spacing(3),
  },

  [`& .${classes.iconContainer}`]: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },

  [`& .${classes.error}`]: {
    color: theme.colors.error,
    width: '100%',
    justifyContent: 'flex-start',
    display: 'flex',
  },

  [`& .${classes.button}`]: {
    marginTop: theme.spacing(3)
  },

  [`& .${classes.browseFilesButton}`]: {
    marginTop: theme.spacing(2)
  },

  [`& .${classes.title}`]: {
    fontSize: theme.fontSize.lg,
    fontWeight: 'bold',
    color: theme.palette.primary.main
  }
}));

interface Props {
  tailoredB62Id: string;
  onClose?: () => void;
  isDialogOpen: boolean;
  onChangeDialogOpen: (isDialogOpen: boolean) => void;
}

const ComparisonScores: React.FC<Props> = ({ tailoredB62Id, onClose, isDialogOpen, onChangeDialogOpen }) => {

  const { userId } = useUser();

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errorMessage, setErrorMessage] = React.useState<string>(""); 
  const [comparisonStatus, setComparisonStatus] = React.useState<string>("");
  const [hasPreviousVersion, setHasPreviousVersion] = React.useState<boolean>(false);

  const [tailoredComp, setTailoredComp] = React.useState<Comparison | undefined>(undefined);
  const [unTailoredComp, setUnTailoredComp] = React.useState<Comparison | undefined>(undefined);

  const dispatch = useDispatch();

  const displayError = React.useCallback((errorMessage: string, errorMessageHtml: string) => {
    dispatch(onAlertShowAction({ type: 'error', message: errorMessage }));
    setErrorMessage(errorMessageHtml);
  }, [dispatch]);

  const fetchComparisonData = React.useCallback(async () => {
    // Skip fetching if we already have a valid status
    if (["Ready", "Edited"].includes(comparisonStatus) && tailoredComp && unTailoredComp) {
      return;
    }
    
    setIsLoading(true);

    try {
      // First get the tailored resume data to check previous_version_id
      const tailoredRes: any = await httpOnGetTailored(tailoredB62Id, userId);
      console.log('Tailored resume data:', tailoredRes.data);
      const hasPrevVersion = !!(tailoredRes.data?.previous_version_id);
      console.log('Setting hasPreviousVersion to:', hasPrevVersion, 'based on previous_version_id:', tailoredRes.data?.previous_version_id);
      setHasPreviousVersion(hasPrevVersion);

      // Then get the comparison data
      const res: any = await httpPollTailoredComparisons(tailoredB62Id, userId);
      console.log('Comparison data:', res.data);
      
      // Only set comparison data if it exists
      if (res.data?.tailored_comparison) {
        setTailoredComp(res.data.tailored_comparison);
      }
      if (res.data?.untailored_comparison) {
        setUnTailoredComp(res.data.untailored_comparison);
      }
      setComparisonStatus(res.data?.comparison_status || "");

      setIsLoading(false);

    } catch (e) {
      const errorMsg = 'Unable to load resume comparison scores.';
      displayError(errorMsg, formatErrorMessageHtml(errorMsg, e));
      setIsLoading(false);
      setComparisonStatus("Failed");
      return;
    }

    setErrorMessage('');
  }, [tailoredB62Id, userId, displayError, comparisonStatus, tailoredComp, unTailoredComp]);

  const handleRetry = React.useCallback(async () => {
    setIsLoading(true);
    setErrorMessage('');
    
    try {
      // First trigger a new comparison generation
      await httpOnTriggerTailoredComparisons(tailoredB62Id, userId);
      
      // Then poll for the results
      await fetchComparisonData();
    } catch (e) {
      const errorMsg = 'Failed to trigger comparison generation.';
      displayError(errorMsg, formatErrorMessageHtml(errorMsg, e));
      setIsLoading(false);
      setComparisonStatus("Failed");
    }
  }, [tailoredB62Id, userId, fetchComparisonData, displayError]);

  React.useEffect(() => {
    fetchComparisonData();
  }, [fetchComparisonData]);

  React.useEffect(() => {
    console.log('ComparisonScores rendering with hasPreviousVersion:', hasPreviousVersion);
  }, [hasPreviousVersion]);

  return (
    <Root className={classes.root}>
      <Box>
        <Box display={'flex'} flexDirection='row'>
          <Box width={'100%'}>
            <ComparisonScoresView 
              tailoredComp={tailoredComp} 
              unTailoredComp={unTailoredComp} 
              isLoading={isLoading} 
              onClose={onClose}
              base62_id={tailoredB62Id}
              comparisonStatus={comparisonStatus}
              onRetry={handleRetry}
              hasPreviousVersion={hasPreviousVersion}
            />
          </Box>
        </Box>
      </Box>
      
      {errorMessage && <Alert severity="error">
            <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
          </Alert>
      }

      <Dialog
          open={isDialogOpen}
          onClose={() => onChangeDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth={'xl'}
      >
          <DialogTitle id="alert-dialog-title"></DialogTitle>
          <DialogContent>
            <ComparisonScoresSideBySideView 
              tailoredComp={tailoredComp} 
              unTailoredComp={unTailoredComp} 
              isLoading={isLoading} 
              onClose={onClose}
              base62_id={tailoredB62Id}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant={'contained'}
              onClick={() => onChangeDialogOpen(false)}
            >
              Close
            </Button>
          </DialogActions>
      </Dialog>
    </Root>
  );
};

export default ComparisonScores;
