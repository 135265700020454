import React, { useEffect } from 'react';
import { AuthProvider } from './contexts/AuthContext';
import { Provider } from 'react-redux';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReduxRouter } from "@lagunovsky/redux-react-router";
import { store, history } from './Redux/Store';
import Layout from './Components/Layout';
import Home from './Pages/Home';
import Landing from './Pages/Landing'; 
import NotFound from './Pages/NotFound';
import SignUp from './Pages/Signup';
import Login from './Pages/Login';
import Alert from './Components/Alert';
import { selectUser } from './Redux';
import Terms from './Pages/Terms';
import Privacy from './Pages/Privacy';
import Profile from './Pages/Profile';
import Logout from './Pages/Logout';
import EditResume from './Pages/EditResume';
import TailoredResumeEdit from './Pages/TailoredResumeEdit';
import PdfResumeViewer from './Pages/PdfResumeViewer';
import ResumeFolder from './Pages/ResumeFolder';
import UserPreferencesPage from './Pages/UserPreferencesPage';
import FeedbackPage from './Pages/FeedbackPage';
import EmailVerify from './Pages/EmailVerify';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ForgotPassword from './Pages/ForgotPassword';
import NewPasswordRequired from './Pages/NewPasswordRequired';
import { initializeTokenRefresh } from './Services/Aws/TokenRefreshInitializer';

// Suppress UNSAFE_componentWillReceiveProps warning from react-draggable-tags
const originalConsoleError = console.error;
console.error = (...args) => {
  if (args[0]?.includes('UNSAFE_componentWillReceiveProps')) {
    return;
  }
  originalConsoleError.apply(console, args);
};

const ForgotPasswordWrapper = () => {
  const user = useSelector(selectUser);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const hasEmailParam = searchParams.has('email');

  // Allow access if there's an email parameter, otherwise apply the guard
  if (user.id && !hasEmailParam) {
    return <Navigate to="/app" />;
  }

  return <ForgotPassword />;
};

const App: React.FC = () => {
  const user = useSelector(selectUser);

  // Initialize token refresh mechanism when the app loads
  useEffect(() => {
    // Only initialize token refresh if user is logged in
    if (user.id) {
      console.log('User is logged in, initializing token refresh mechanism');
      initializeTokenRefresh();
    }
  }, [user.id]); // Re-run when user ID changes (login/logout)

  return (
    <GoogleOAuthProvider 
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
    >
      <AuthProvider>
        <Provider store={store}>
          <ReduxRouter history={history}>
            <Routes>
              {/* Landing Page Route */}
              <Route path="/" element={user.id ? <Navigate to="/app" /> : <Layout user={user} containerDisableGutters={true} containerMaxWidth={'xl'}/>}>
                <Route index element={<Landing user={user} />} /> 
              </Route>

              {/* Protected Routes */}
              <Route path="/app" element={user.id ? <Layout user={user} /> : <Navigate to="/" /> }>
                <Route index element={<Home />} />
                <Route path=":resumeB62Id" element={<Home />} />
                <Route path=":resumeB62Id/edit" element={<EditResume />} />
                <Route path=":resumeB62Id/folder" element={<ResumeFolder />} />
                <Route path=":resumeB62Id/job/:jobB62Id/tailored-resume/:tailoredB62Id" element={<TailoredResumeEdit />}  />
                <Route path=":resumeB62Id/job/:jobB62Id/tailored-resume/:tailoredB62Id/latex/:latexB62Id" element={<PdfResumeViewer  /> }   />
                <Route path="profile" element={<Profile />} />
                <Route path="user-preferences" element={<UserPreferencesPage />} />
              </Route>

              {/* Authentication Routes */}
              <Route path="login" element={user.id ? <Navigate to="/app" /> : <Login />} />
              <Route path="signup" element={user.id ? <Navigate to="/app" /> : <SignUp />} />
              <Route path="logout" element={<Logout />} />
              <Route path="forgot-password" element={<ForgotPasswordWrapper />} />
              <Route path="new-password" element={<NewPasswordRequired />} />

              <Route path="feedback" element={<Layout user={user} containerDisableGutters={true} containerMaxWidth={'xl'}/>}>
                <Route index element={<FeedbackPage isLoggedIn={user.id} /> } />
              </Route>

              {/* Verify email route */}
              <Route path="/verify-email" element={user.id ? <Navigate to="/app" /> : <Layout user={user} containerDisableGutters={true} containerMaxWidth={'xl'}/>}>
                <Route index element={<EmailVerify />} /> 
              </Route>

              {/* Static Pages */}
              <Route path="terms" element={<Terms />} />
              <Route path="privacy" element={<Privacy />} />

              {/* Catch-all for not found pages */}
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Alert />
          </ReduxRouter>
        </Provider>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
