import UserPool from '../../Configs/UserPool';
import { CognitoUserSession, CognitoIdToken, CognitoAccessToken, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { getTimeUntilExpiration, testRefreshFlow } from './SessionUtils';
import { cleanupLegacyStorage, fixRefreshTokenKeys, cleanupAllTokenKeys } from './SessionUtils';

// Storage key prefix for all Cognito-related items
const STORAGE_KEY_PREFIX = 'CognitoIdentityServiceProvider';
const CLIENT_ID = process.env.REACT_APP_USER_POOL_CLIENT_ID || '';

// Safe storage utility to prevent undefined values
const safeSetItem = (key: string, value: string | null | undefined) => {
    if (!key || key.includes('undefined') || key.includes('null')) {
        console.warn('🚫 Attempted to use invalid storage key:', key);
        return false;
    }
    if (value === undefined || value === null) {
        console.warn('🚫 Attempted to store undefined/null value for key:', key);
        return false;
    }
    try {
        localStorage.setItem(key, value);
        return true;
    } catch (error) {
        console.error('❌ Error storing value:', error);
        return false;
    }
};

// Fix any refresh tokens with incorrect keys (with "undefined" in them)
// Call the fix function when this module is loaded
fixRefreshTokenKeys(safeSetItem);

interface UserData {
    id: string;
    email: string;
    name: string;
    image: string;
    accessToken: string;
    idToken: string;
}

// Add function to clean up legacy storage formats
// Update getStoredTokens to handle cleanup
export const getStoredTokens = (skipCleanup: boolean = false) => {
    // Check for old format first and migrate if needed
    const persistRoot = localStorage.getItem('persist:root');
    if (persistRoot && !skipCleanup) {
        try {
            const persistData = JSON.parse(persistRoot);
            if (persistData.user) {
                const userData = JSON.parse(persistData.user);
                if (userData.userId && userData.accessToken) {
                    console.log('📦 Found old Google auth format in getStoredTokens, triggering cleanup...');
                    cleanupLegacyStorage(safeSetItem);
                }
            }
        } catch (error) {
            console.error('❌ Error checking persist:root:', error);
        }
    }

    // Try Cognito format first
    const userId = localStorage.getItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.LastAuthUser`);
    if (userId) {
        const accessToken = localStorage.getItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${userId}.accessToken`);
        const idToken = localStorage.getItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${userId}.idToken`);
        
        if (accessToken && idToken) {
            return { accessToken, userId, idToken };
        }
    }

    // Try legacy format
    const legacyToken = localStorage.getItem('cognitoAccessToken');
    const legacyUserId = localStorage.getItem('userId');
    if (legacyToken && legacyUserId) {
        // Migrate to new format
        safeSetItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.LastAuthUser`, legacyUserId);
        safeSetItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${legacyUserId}.accessToken`, legacyToken);
        safeSetItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${legacyUserId}.idToken`, legacyToken);
        
        // Clean up legacy format only if not already cleaning
        if (!skipCleanup) {
            cleanupLegacyStorage(safeSetItem);
        }
        
        return { accessToken: legacyToken, userId: legacyUserId, idToken: legacyToken };
    }

    // Try simplified format (another legacy)
    const simpleUserId = localStorage.getItem('LastAuthUser');
    if (simpleUserId) {
        const simpleAccessToken = localStorage.getItem('accessToken');
        const simpleIdToken = localStorage.getItem('idToken');
        if (simpleAccessToken && simpleIdToken) {
            // Migrate to new format
            safeSetItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.LastAuthUser`, simpleUserId);
            safeSetItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${simpleUserId}.accessToken`, simpleAccessToken);
            safeSetItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${simpleUserId}.idToken`, simpleIdToken);
            
            // Clean up simplified format only if not already cleaning
            if (!skipCleanup) {
                cleanupLegacyStorage(safeSetItem);
            }
            
            return { accessToken: simpleAccessToken, userId: simpleUserId, idToken: simpleIdToken };
        }
    }

    // If no valid tokens found, clean up any partial storage only if not already cleaning
    if (!skipCleanup) {
        cleanupLegacyStorage(safeSetItem);
    }
    return null;
}

// Update awsOnGetSession to handle migration
export const awsOnGetSession = (): Promise<UserData> => {
    return new Promise((resolve, reject) => {
        console.log('🔍 Getting session...');
        // Try stored tokens first
        const tokens = getStoredTokens(true); // Pass skipCleanup=true
        if (tokens) {
            console.log('📝 Found stored tokens, checking validity...');
            
            // Verify token validity
            try {
                const decodedToken = JSON.parse(atob(tokens.accessToken.split('.')[1]));
                const expirationTime = decodedToken.exp * 1000;
                const timeUntilExpiration = expirationTime - Date.now();
                
                // Format time until expiration in minutes and seconds
                const minutes = Math.floor(timeUntilExpiration / 60000);
                const seconds = Math.floor((timeUntilExpiration % 60000) / 1000);
                const expiryDate = new Date(expirationTime);
                
                console.log(`⏱️ Token expires in ${minutes}m ${seconds}s (at ${expiryDate.toLocaleTimeString()})`);
                
                if (expirationTime > Date.now()) {
                    console.log('✅ Stored token is still valid');
                    resolve({ 
                        id: tokens.userId,
                        email: '', // These will be populated from Redux state
                        name: '',
                        image: '',
                        accessToken: tokens.accessToken,
                        idToken: tokens.idToken
                    });
                    return;
                }
            } catch (error) {
                console.error('❌ Error decoding stored token:', error);
            }
            console.log('⚠️ Stored token is expired, trying Cognito session');
        } else {
            console.log('📭 No stored tokens found, trying Cognito session');
        }

        // Fall back to Cognito session
        const user = UserPool.getCurrentUser();
        if (user) {
            console.log('👤 Found Cognito user, getting session...');
            
            // Define normal session flow
            const getSessionNormally = () => {
                user.getSession((err: Error | null, session: CognitoUserSession | null) => {
                    if (err || !session) {
                        console.error('❌ Error getting Cognito session:', err);
                        // Instead of rejecting immediately, try to authenticate again
                        console.log('⚠️ Session error, need to authenticate again');
                        reject(new Error("Cannot retrieve a new session. Please authenticate."));
                        return;
                    }

                    if (!session.isValid()) {
                        console.log('⚠️ Cognito session is invalid, attempting refresh...');
                        const refreshToken = session.getRefreshToken();
                        if (!refreshToken) {
                            console.error('❌ No refresh token available');
                            reject(new Error("No refresh token available"));
                            return;
                        }

                        user.refreshSession(refreshToken, (refreshErr: any, newSession: CognitoUserSession) => {
                            if (refreshErr) {
                                console.error('❌ Failed to refresh session:', refreshErr);
                                reject(refreshErr);
                                return;
                            }
                            console.log('✅ Successfully refreshed Cognito session');
                            const accessToken = newSession.getAccessToken();
                            const idToken = newSession.getIdToken();
                            const username = accessToken.payload.username;
                            const email = idToken.payload.email;
                            const nickname = idToken.payload.nickname;

                            const userData = { 
                                id: username, 
                                email, 
                                name: nickname, 
                                image: '', 
                                accessToken: accessToken.getJwtToken(),
                                idToken: idToken.getJwtToken()
                            };

                            resolve(userData);
                        });
                        return;
                    }

                    console.log('✅ Got valid Cognito session');
                    const accessToken = session.getAccessToken();
                    const idToken = session.getIdToken();
                    const username = accessToken.payload.username;
                    const email = idToken.payload.email;
                    const nickname = idToken.payload.nickname;

                    const userData = { 
                        id: username, 
                        email, 
                        name: nickname, 
                        image: '', 
                        accessToken: accessToken.getJwtToken(),
                        idToken: idToken.getJwtToken()
                    };

                    resolve(userData);
                });
            };
            
            // Try to get stored refresh token first
            const storedRefreshToken = tokens?.userId ? 
                localStorage.getItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${tokens.userId}.refreshToken`) : 
                null;
            
            if (storedRefreshToken) {
                console.log('🔄 Found stored refresh token, attempting refresh...');
                try {
                    const refreshToken = new CognitoRefreshToken({ RefreshToken: storedRefreshToken });
                    user.refreshSession(refreshToken, (refreshErr: any, newSession: CognitoUserSession) => {
                        if (refreshErr) {
                            console.error('❌ Failed to refresh with stored token:', refreshErr);
                            // Fall back to normal session flow
                            getSessionNormally();
                            return;
                        }
                        console.log('✅ Successfully refreshed session with stored token');
                        const accessToken = newSession.getAccessToken();
                        const idToken = newSession.getIdToken();
                        const username = accessToken.payload.username;
                        const email = idToken.payload.email;
                        const nickname = idToken.payload.nickname;

                        const userData = { 
                            id: username, 
                            email, 
                            name: nickname, 
                            image: '', 
                            accessToken: accessToken.getJwtToken(),
                            idToken: idToken.getJwtToken()
                        };

                        resolve(userData);
                    });
                    return;
                } catch (error) {
                    console.error('❌ Error using stored refresh token:', error);
                }
            }

            // Normal session flow
            getSessionNormally();
        } else {
            console.error('❌ No current Cognito user found');
            reject(new Error("No current user found"));
        }
    });
}

const authHeaderName = 'Authorization';

// Add a token refresh queue mechanism
let isRefreshingToken = false;
let refreshQueue: Array<{
  resolve: (value: any) => void;
  reject: (reason?: any) => void;
}> = [];

// Process all queued promises with the new token or error
const processRefreshQueue = (error: any = null, token: string | null = null) => {
  refreshQueue.forEach(promise => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });
  
  // Reset the queue
  refreshQueue = [];
  isRefreshingToken = false;
};

// Modify refreshToken to use the queue
const refreshToken = async () => {
  // If already refreshing, add to queue
  if (isRefreshingToken) {
    console.log('🔄 Token refresh already in progress, adding to queue');
    return new Promise((resolve, reject) => {
      refreshQueue.push({ resolve, reject });
    });
  }
  
  // Set refreshing flag
  isRefreshingToken = true;
  console.log('🔄 Starting token refresh');
  
  return new Promise((resolve, reject) => {
    const user = UserPool.getCurrentUser();
    if (!user) {
      console.error('❌ No current user found in UserPool');
      isRefreshingToken = false;
      const error = new Error('No current user');
      processRefreshQueue(error);
      reject(error);
      return;
    }

    user.getSession((err: any, session: any) => {
      if (err) {
        console.error('❌ Error getting session:', err);
        isRefreshingToken = false;
        processRefreshQueue(err);
        reject(err);
        return;
      }

      if (!session.isValid()) {
        console.log('⚠️ Session expired, refreshing tokens...');
        const refreshToken = session.getRefreshToken();
        
        user.refreshSession(refreshToken, (err: any, newSession: any) => {
          if (err) {
            console.error('❌ Error refreshing session:', err);
            isRefreshingToken = false;
            processRefreshQueue(err);
            reject(err);
            return;
          }
          
          // Get the new tokens
          const accessToken = newSession.getAccessToken().getJwtToken();
          const idToken = newSession.getIdToken().getJwtToken();
          const userId = newSession.getAccessToken().payload.username;
          
          // Store tokens in Cognito format
          localStorage.setItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${userId}.accessToken`, accessToken);
          localStorage.setItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${userId}.idToken`, idToken);
          
          console.log('✅ Token refresh successful, processing queue');
          processRefreshQueue(null, accessToken);
          resolve(newSession);
        });
      } else {
        console.log('✅ Session is still valid');
        const accessToken = session.getAccessToken().getJwtToken();
        processRefreshQueue(null, accessToken);
        resolve(session);
      }
    });
  });
};

// Update setAuthHeader to use the token refresh queue
export const setAuthHeader = async (config: Record<string, any>): Promise<Record<string, any>> => {
  try {
    // Try stored tokens first
    const tokens = getStoredTokens();
    if (tokens) {
      // Check if token is expired or will expire soon
      try {
        const decodedToken = JSON.parse(atob(tokens.accessToken.split('.')[1]));
        const expirationTime = decodedToken.exp * 1000;
        const timeUntilExpiration = expirationTime - Date.now();
        
        // If token expires in less than 30 seconds, refresh it
        if (timeUntilExpiration < 30000) {
          console.log('⚠️ Token expires soon, refreshing before API call');
          await refreshToken();
          // Get fresh tokens after refresh
          const freshTokens = getStoredTokens();
          if (freshTokens) {
            if (!config.headers) {
              config.headers = {};
            }
            config.headers[authHeaderName] = `Bearer ${freshTokens.accessToken}`;
            return config;
          }
        } else {
          // Token is valid, use it
          if (!config.headers) {
            config.headers = {};
          }
          config.headers[authHeaderName] = `Bearer ${tokens.accessToken}`;
          return config;
        }
      } catch (error) {
        console.error('❌ Error decoding token, will try to refresh:', error);
        await refreshToken();
        // Get fresh tokens after refresh
        const freshTokens = getStoredTokens();
        if (freshTokens) {
          if (!config.headers) {
            config.headers = {};
          }
          config.headers[authHeaderName] = `Bearer ${freshTokens.accessToken}`;
          return config;
        }
      }
    }

    // Fall back to getting a fresh session
    const session = await awsOnGetSession();
    if (!config.headers) {
      config.headers = {};
    }
    config.headers[authHeaderName] = `Bearer ${session.accessToken}`;
    return config;
  } catch (err) {
    console.error("Error setting auth header:", err);
    throw err;
  }
};

// Add a function to check if token needs refresh
export async function checkAndRefreshTokens() {
    try {
        const tokens = getStoredTokens();
        if (!tokens) {
            return null;
        }

        // Decode the access token to check expiration
        const { accessToken } = tokens;
        const decodedToken = JSON.parse(atob(accessToken.split('.')[1]));
        const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
        const currentTime = Date.now();
        const timeUntilExpiration = expirationTime - currentTime;
        
        // If token expires in less than 5 minutes, refresh it
        if (timeUntilExpiration < 300000) {
            console.log('⏱️ Token expires in less than 5 minutes, refreshing');
            await refreshToken(); // This now uses the queue mechanism
            return getStoredTokens();
        }
        
        return tokens;
    } catch (error) {
        console.error('❌ Error checking/refreshing tokens:', error);
        return null;
    }
}

// Update the SessionTest interface definition
interface Window {
    SessionTest: {
        getTimeUntilExpiration: () => { 
            accessToken: { seconds: number, formatted: string, expiresAt: string } 
        } | null;
        checkAndRefreshTokens: () => Promise<any>;
        cleanupLegacyStorage: () => boolean;
        scheduleTokenRefresh: () => void;
        testRefreshFlow: () => Promise<boolean>;
        cleanupAllTokenKeys: () => boolean;
    }
}

// Update the setup of the window.SessionTest object
if (typeof window !== 'undefined') {
    window.SessionTest = window.SessionTest || {};
    
    // Define accessors
    const getTimeUntilExpirationWrapper = () => getTimeUntilExpiration(getStoredTokens);
    const cleanupLegacyStorageWrapper = () => cleanupLegacyStorage(safeSetItem);
    const testRefreshFlowWrapper = () => testRefreshFlow(getStoredTokens, safeSetItem);
    const cleanupAllTokenKeysWrapper = () => cleanupAllTokenKeys();
    
    // Set up the SessionTest object
    window.SessionTest.getTimeUntilExpiration = getTimeUntilExpirationWrapper;
    window.SessionTest.checkAndRefreshTokens = checkAndRefreshTokens;
    window.SessionTest.cleanupLegacyStorage = cleanupLegacyStorageWrapper;
    window.SessionTest.scheduleTokenRefresh = scheduleTokenRefresh;
    window.SessionTest.testRefreshFlow = testRefreshFlowWrapper;
    window.SessionTest.cleanupAllTokenKeys = cleanupAllTokenKeysWrapper;
}

export interface TokenInfo {
  accessToken: string;
  idToken: string;
  refreshToken?: string;
  expiresAt?: number;
}

export const checkSessionExpiration = async (): Promise<boolean> => {
    try {
        const user = UserPool.getCurrentUser();
        if (!user) {
            return false;
        }

        return new Promise((resolve) => {
            user.getSession((err: any, session: CognitoUserSession) => {
                if (err || !session) {
                    resolve(false);
                    return;
                }

                // Check token expiration
                const expirationTime = session.getAccessToken().getExpiration() * 1000;
                const currentTime = Date.now();
                const timeUntilExpiration = expirationTime - currentTime;
                
                // Refresh if token expires in less than 5 minutes
                resolve(timeUntilExpiration < 5 * 60 * 1000 && timeUntilExpiration > 0);
            });
        });
    } catch (error) {
        console.error('Error checking session expiration:', error);
        return false;
    }
};

// Update shouldRedirectToLogin to include logging and more robust protection
export const shouldRedirectToLogin = (): boolean => {
    console.log('🔄 Checking if should redirect to login...');
    
    // Check if we're already on the login page
    if (window.location.pathname === '/login') {
        console.log('⚠️ Already on login page, preventing redirect');
        return false;
    }
    
    const lastRedirectTime = localStorage.getItem('lastLoginRedirect');
    const currentTime = Date.now();
    
    if (lastRedirectTime) {
        const timeSinceLastRedirect = currentTime - parseInt(lastRedirectTime);
        console.log(`⏱️ Time since last redirect: ${timeSinceLastRedirect}ms`);
        
        // More aggressive prevention - prevent redirect if last redirect was less than 10 seconds ago
        if (timeSinceLastRedirect < 10000) {
            console.log('⚠️ Preventing redirect - too soon since last redirect');
            return false;
        }
    }
    
    // Check if we've had too many redirects in a short period
    const redirectCountKey = 'loginRedirectCount';
    const redirectTimeWindowKey = 'loginRedirectTimeWindow';
    
    const redirectCount = parseInt(localStorage.getItem(redirectCountKey) || '0');
    const redirectTimeWindow = parseInt(localStorage.getItem(redirectTimeWindowKey) || '0');
    
    // If we're in a new time window (30 seconds), reset the count
    if (currentTime - redirectTimeWindow > 30000) {
        localStorage.setItem(redirectCountKey, '1');
        localStorage.setItem(redirectTimeWindowKey, currentTime.toString());
    } else {
        // Increment the count
        localStorage.setItem(redirectCountKey, (redirectCount + 1).toString());
        
        // If we've had too many redirects in the time window, prevent further redirects
        if (redirectCount >= 3) {
            console.log(`⚠️ Preventing redirect - too many redirects (${redirectCount}) in time window`);
            return false;
        }
    }
    
    console.log('✅ Allowing redirect to login');
    localStorage.setItem('lastLoginRedirect', currentTime.toString());
    return true;
};

// Add a background token refresh mechanism
let tokenRefreshTimer: ReturnType<typeof setTimeout> | null = null;

// Function to schedule background token refresh
export function scheduleTokenRefresh() {
    // Clear any existing timer
    if (tokenRefreshTimer) {
        clearTimeout(tokenRefreshTimer);
        tokenRefreshTimer = null;
        console.log('🔄 Cleared existing token refresh timer');
    }

    try {
        // Get current tokens
        const tokens = getStoredTokens();
        if (!tokens || !tokens.userId) {
            console.log('No tokens found for background refresh scheduling');
            return;
        }

        console.log('🔍 Scheduling token refresh for user:', tokens.userId);

        // Get token expiration time
        let expirationTime: number | null = null;
        
        // Try to decode the token
        try {
            const decodedToken = JSON.parse(atob(tokens.accessToken.split('.')[1]));
            if (decodedToken.exp) {
                expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
            }
        } catch (error) {
            console.error('Error decoding token for refresh scheduling:', error);
            return;
        }
        
        if (!expirationTime) {
            console.log('Could not determine token expiration time for background refresh');
            return;
        }
        
        // Calculate time until token expires
        const currentTime = Date.now();
        const timeUntilExpiration = expirationTime - currentTime;
        
        // Format time until expiration for logging
        const minutesToExpiration = Math.floor(timeUntilExpiration / 60000);
        const secondsToExpiration = Math.floor((timeUntilExpiration % 60000) / 1000);
        const expiryDate = new Date(expirationTime);
        
        console.log(`⏱️ Token expires in ${minutesToExpiration}m ${secondsToExpiration}s (at ${expiryDate.toLocaleTimeString()})`);
        
        // Calculate refresh interval as 75% of the time until expiration
        const REFRESH_PERCENTAGE = 0.75;
        let refreshIntervalMs = Math.floor(timeUntilExpiration * REFRESH_PERCENTAGE);
        
        // Safeguard: Ensure refresh interval is at least 5 minutes to prevent rapid refreshes
        const MIN_REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minutes in milliseconds
        if (refreshIntervalMs < MIN_REFRESH_INTERVAL && timeUntilExpiration > MIN_REFRESH_INTERVAL) {
            console.log(`⚠️ Calculated refresh interval (${refreshIntervalMs}ms) is too short, using minimum of ${MIN_REFRESH_INTERVAL}ms`);
            refreshIntervalMs = MIN_REFRESH_INTERVAL;
        }
        
        // If token expires in less than 5 minutes, refresh immediately
        if (timeUntilExpiration < 300000) {
            console.log(`🔄 Token expires in ${minutesToExpiration}m ${secondsToExpiration}s, which is less than 5m, refreshing immediately`);
            // Add a flag to prevent immediate re-scheduling
            const preventReschedule = true;
            refreshSession(preventReschedule).catch(err => console.error('Background token refresh failed:', err));
            return;
        }
        
        // Schedule refresh at 75% of the time until expiration
        const refreshTime = new Date(Date.now() + refreshIntervalMs);
        const refreshMinutes = Math.floor(refreshIntervalMs / 60000);
        const refreshSeconds = Math.floor((refreshIntervalMs % 60000) / 1000);
        
        console.log(`Scheduling background token refresh in ${refreshMinutes}m ${refreshSeconds}s (at ${refreshTime.toLocaleTimeString()}) (75% of expiry time)`);
        
        // Schedule the refresh
        tokenRefreshTimer = setTimeout(async () => {
            console.log('🔄 Executing background token refresh');
            try {
                const newTokens = await refreshSession();
                if (newTokens) {
                    console.log('✅ Background token refresh successful');
                    // Schedule the next refresh
                    scheduleTokenRefresh();
                }
            } catch (error) {
                console.error('❌ Background token refresh failed:', error);
                // Try again in 30 seconds if refresh fails
                tokenRefreshTimer = setTimeout(() => scheduleTokenRefresh(), 30000);
            }
        }, refreshIntervalMs);
    } catch (error) {
        console.error('Error scheduling token refresh:', error);
    }
}

// Modify refreshSession to use our token refresh queue
export const refreshSession = async (preventReschedule: boolean = false): Promise<TokenInfo | null> => {
    // First, try to fix any invalid refresh token keys
    fixRefreshTokenKeys(localStorage.setItem.bind(localStorage));
    
    // If a refresh is already in progress, wait for it to complete
    if (isRefreshingToken) {
        console.log('🔄 Token refresh already in progress, waiting for completion');
        try {
            await new Promise((resolve, reject) => {
                refreshQueue.push({ resolve, reject });
            });
            console.log('✅ Refresh completed by another process');
            
            // Get the updated tokens
            const tokens = getStoredTokens();
            if (tokens) {
                const user = UserPool.getCurrentUser();
                if (!user) return null;
                
                return {
                    accessToken: tokens.accessToken,
                    idToken: tokens.idToken,
                    refreshToken: undefined, // Use undefined instead of null
                    expiresAt: undefined // Use undefined instead of null
                };
            }
            return null;
        } catch (error) {
            console.error('❌ Error waiting for token refresh:', error);
            return null;
        }
    }
    
    const user = UserPool.getCurrentUser();
    if (!user) {
        console.error('❌ No current user for refresh');
        return null;
    }

    return new Promise((resolve, reject) => {
        user.getSession((err: any, session: CognitoUserSession) => {
            if (err) {
                console.error('❌ Error getting session for refresh:', err);
                reject(err);
                return;
            }

            if (!session.isValid()) {
                // Set refreshing flag
                isRefreshingToken = true;
                console.log('🔄 Starting token refresh in refreshSession');
                
                const refreshToken = session.getRefreshToken();
                user.refreshSession(refreshToken, (err: any, newSession: CognitoUserSession) => {
                    if (err) {
                        console.error('❌ Error during token refresh:', err);
                        isRefreshingToken = false;
                        processRefreshQueue(err);
                        reject(err);
                        return;
                    }

                    const tokens: TokenInfo = {
                        accessToken: newSession.getAccessToken().getJwtToken(),
                        idToken: newSession.getIdToken().getJwtToken(),
                        refreshToken: newSession.getRefreshToken().getToken(),
                        expiresAt: newSession.getAccessToken().getExpiration() * 1000
                    };

                    // Store the new tokens
                    const userId = user.getUsername();
                    safeSetItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${userId}.accessToken`, tokens.accessToken);
                    safeSetItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${userId}.idToken`, tokens.idToken);
                    if (tokens.refreshToken) {
                        safeSetItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${userId}.refreshToken`, tokens.refreshToken);
                    }

                    console.log('✅ Token refresh successful, processing queue');
                    processRefreshQueue(null, tokens.accessToken);
                    
                    // Schedule the next token refresh, but only if not prevented
                    if (!preventReschedule) {
                        scheduleTokenRefresh();
                    } else {
                        console.log('🛑 Skipping immediate re-schedule of token refresh');
                    }
                    
                    resolve(tokens);
                });
            } else {
                // Session is still valid, return current tokens and update expiration
                const tokens: TokenInfo = {
                    accessToken: session.getAccessToken().getJwtToken(),
                    idToken: session.getIdToken().getJwtToken(),
                    refreshToken: session.getRefreshToken().getToken(),
                    expiresAt: session.getAccessToken().getExpiration() * 1000
                };

                // Schedule the next token refresh, but only if not prevented
                if (!preventReschedule) {
                    scheduleTokenRefresh();
                } else {
                    console.log('🛑 Skipping immediate re-schedule of token refresh');
                }
                
                resolve(tokens);
            }
        });
    });
};

/**
 * Store authentication state in local storage
 */
export const storeAuthState = (
    cognitoId: string,
    accessToken: string,
    idToken: string,
    refreshToken: string | null,
    authProvider: string,
    expiresAt?: number
) => {
    // Store tokens in cognito format
    safeSetItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.LastAuthUser`, cognitoId);
    safeSetItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${cognitoId}.accessToken`, accessToken);
    safeSetItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${cognitoId}.idToken`, idToken);
    
    if (refreshToken) {
        safeSetItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${cognitoId}.refreshToken`, refreshToken);
    }
    
    safeSetItem('authProvider', authProvider);
};