import React, { useState } from 'react';
import { Box, IconButton, Typography, Chip, TextField } from '@mui/material';
import { Control, useFieldArray, Controller } from 'react-hook-form';
import {
  DeleteForever,
  Add as IconAdd,
  Close as CloseIcon,
} from '@mui/icons-material';
import { getId } from './utils';
import TextDiffComponent from './TextDiffComponent';
import { FormValues } from './ResumeForm';
import { borderedSectionStyles } from './styles';
import theme from '../../theme';

interface Props {
  control: Control<FormValues>;
  _required?: boolean;
  diffOriginData?: any;
  showDiff?: boolean;
}

const ResumeFormExtracurricular: React.FC<Props> = ({ control, _required, diffOriginData, showDiff }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'extracurricular',
  });

  const [deletingActivityIndex, setDeletingActivityIndex] = useState<number | null>(null);

  const handleAddActivity = () => {
    append('');
  };

  return (
    <Box>
      <Typography>Extracurricular Activities
        <IconButton onClick={handleAddActivity}
          disabled={fields.length > 0 && !fields[fields.length - 1]}>
          <IconAdd/>
        </IconButton>
      </Typography>

      {fields.map((field, index) => (
        <Box key={field.id} sx={{display: 'flex', position: 'relative', pb: 1}}>
          <Box sx={{position: 'absolute', left: theme.spacing(-1), top: theme.spacing(-1)}}>
            <Chip label={index + 1} size="small" sx={{color: theme.palette.text.secondary}} />
          </Box>

          <Box sx={{
            flexGrow: 1, 
            ...borderedSectionStyles,
            ...(deletingActivityIndex === index && {
              borderColor: `${theme.palette.error.main} !important`,
              '& .MuiInputBase-input': {
                color: `${theme.palette.error.main} !important`,
                textDecoration: 'line-through',
              }
            })
          }}>
            <Controller
              name={`extracurricular.${index}`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <TextField
                    id={getId(`extracurricular.${index}`)}
                    fullWidth
                    value={value || ''}
                    onChange={(e) => onChange(e.target.value)}
                    size="small"
                    multiline={true}
                    sx={{ mt: 1, mb: 1 }}
                  />
                  {showDiff && (
                    <TextDiffComponent
                      originalText={diffOriginData?.[index] || ''}
                      updatedText={value || ''}
                      onTextUpdated={onChange}
                    />
                  )}
                </>
              )}
            />
          </Box>

          <Box pt={'13px'}>
            {deletingActivityIndex === index ? (
              <Box sx={{ display: 'flex', gap: 0.5 }}>
                <IconButton 
                  size="small"
                  onClick={() => {
                    remove(index);
                    setDeletingActivityIndex(null);
                  }}
                  sx={{ color: theme.palette.error.main }}
                >
                  <DeleteForever/>
                </IconButton>
                <IconButton 
                  size="small"
                  onClick={() => setDeletingActivityIndex(null)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : (
              <IconButton 
                onClick={() => setDeletingActivityIndex(index)}
              >
                <DeleteForever/>
              </IconButton>
            )}
          </Box>
        </Box>
      ))}

      {fields.length > 0 && 
        <Typography variant="body1" sx={{fontSize: '12px'}}>Add More Activities
          <IconButton onClick={handleAddActivity}
            disabled={fields.length > 0 && !fields[fields.length - 1]}>
            <IconAdd/>
          </IconButton>
        </Typography>
      }
    </Box>
  );
};

export default ResumeFormExtracurricular; 