import { scheduleTokenRefresh } from './Session';

/**
 * Initialize the token refresh mechanism
 * This should be called when the application starts
 */
export const initializeTokenRefresh = () => {
    // Debug current tokens
    // debugCognitoTokens();
    
    // Schedule background token refresh
    scheduleTokenRefresh();
    
    console.log('✅ Token refresh mechanism initialized');
};

// Export a function to manually trigger a token refresh
export const triggerTokenRefresh = () => {
    console.log('🔄 Manually triggering token refresh');
    scheduleTokenRefresh();
}; 