import React, { useState } from "react";
import { Box, IconButton, TextareaAutosize, TextareaAutosizeProps, Tooltip, alpha, styled } from "@mui/material";

import { grey} from '@mui/material/colors';
import { ContentPasteGo, DeleteOutline } from "@mui/icons-material";


const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
  height: 100% !important;
  box-sizing: border-box;
  width: 320px;
  // font-family: 'IBM Plex Sans', sans-serif;
  font-family: '"Courier New", Courier, monospace',
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

  &:hover {
    border-color: ${theme.palette.primary};
  }

  &:focus {
    outline: 0;
    border-color: ${theme.palette.primary};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? theme.palette.primary.dark : alpha(theme.palette.primary.light, 0.6)};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

interface Props {
}

const Textarea: React.FC<TextareaAutosizeProps & React.RefAttributes<Element> & Props & any> = (props) => {
  const [value, setValue] = useState<string>(props.value || '');
  
  // Create a new props object without defaultValue to avoid React warning
  const { defaultValue, ...restProps } = props;

  const handlePasteFromClipboard = async () => {
      try {
          const text = await navigator.clipboard.readText();
          setValue(text);
          props.onChange && props.onChange({target: {value: text}});
      } catch (err) {
          console.error('Error pasting text from clipboard', err);
      }
  };

  const handleOnChange = (event: any) => {
    setValue(event.target.value);
    props.onChange && props.onChange(event);
  }

  return (
    <>
      <Box
        sx={{ display: 'flex', height: '100%'}}
      >
        <Box display={'flex'} alignItems={'center'} flexDirection={'column'} justifyContent={'center'}>
          <Box>
            <Tooltip title="Paste from Clipboard">
                <IconButton onClick={handlePasteFromClipboard} disabled={props.disabled} size="large">
                    <ContentPasteGo />
                </IconButton>
            </Tooltip>
          </Box>
          <Box>
            <Tooltip title="Clear">
                <IconButton onClick={() => setValue('')} disabled={props.disabled} size="large">
                    <DeleteOutline fontSize="inherit" />
                </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Box
          flexGrow={1}
          sx={{ height: '100%'}}
        >
          <StyledTextarea
              {...restProps}
              value={value}
              onChange={handleOnChange}
          >
          </StyledTextarea>
        </Box>
        
      </Box>
    </>
  )
}


export default Textarea;