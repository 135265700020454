import { Box, Button, CircularProgress, IconButton, LinearProgress, Skeleton, Tooltip, Typography, styled, Drawer, ButtonGroup, Alert, alpha, darken} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Cancel, Download, Edit, EditNote, Folder, OpenInFull, Speed, VisibilityOff } from "@mui/icons-material";
import GridViewSharpIcon from '@mui/icons-material/GridViewSharp';
import { useNavigate, useParams } from "react-router-dom";
import { httpOnCreatePdf, httpOnGetJobDescription, httpOnDeleteResume, httpOnGetResume, formatErrorMessageHtml, httpOnGetTailored } from "../Services/Http";
import useUser from "../Hooks/useUser";
import theme from "../theme";
import { useDispatch } from "react-redux";
import { onAlertShowAction } from "../Redux/Alert/Actions";
//import { Comparison } from "../Services/Http/interface";
import ComparisonScores from "../Components/ComparisonScores";
import { formatUpdatedAt } from "../Services/Util/dateFormat";
import ResumeTitle from "../Components/ResumeTitle";
import FeedbackSidePopup from "../Components/FeedbackSidePopup";
import ConfirmDialogBox from "../Components/ConfirmDialogBox";

import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';

const debugLogs = process.env.REACT_APP_DEBUG_LOGS;


const drawerWidth = 400;
const drawerSpaceDividerWidth = 16;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme }) => ({
  flexGrow: 1,
  // padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: -drawerWidth,
  [theme.breakpoints.down('md')]: {
    marginRight: 0,
  },
  /**
   * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
   * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
   * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
   * proper interaction with the underlying content.
   */
  position: 'relative',
  variants: [
    {
      props: ({ open }) => open,
      style: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
      },
    },
  ],
}));

// src/pages/PdfResumeViewer.js
export default function PdfResumeViewer() {

  const [fileName, setFileName] = React.useState<string>("");
  const [resumeUpdatedAt, setResumeUpdatedAt] = React.useState<string>("");
  const [jobTitle, setJobTitle] = useState<string>("");
  const [jobCompany, setJobCompany] = useState<string>("");
  const [latexUpdatedAt, setLatexUpdatedAt] = useState<string>("");
  const [hasPreviousVersion, setHasPreviousVersion] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isLoadingDownload, setIsLoadingDownload] = React.useState<boolean>(false);
  const [generateInProgress, setGenerateInProgress] = React.useState<boolean>(false);
  const [removeResumeInProgress, setRemoveResumeInProgress] = React.useState<boolean>(false);
  const [removeResumeDialogOpen, setRemoveResumeDialogOpen] = useState(false);



  const navigate = useNavigate();
  const params = useParams();
  const { userId } = useUser();

  const dispatch = useDispatch();

  const [pdfData, setPdfData] = React.useState<any>(null);
  // eslint-disable-next-line 
  const [resumeB62Id, setResumeB62Id] = React.useState<string>("");

  const [isEditButtonTooltipOpen, setIsEditButtonTooltipOpen] = useState(false);

  useEffect(() => {
    // Check if user has already dismissed the tooltip
    const hasSeenTooltip = localStorage.getItem('hasSeenEditTooltip');
    
    if (!hasSeenTooltip) {
      setTimeout(() => {
        setIsEditButtonTooltipOpen(true);
      }, 1000);
    }
  }, []);

  const handleTooltipClose = () => {
    setIsEditButtonTooltipOpen(false);
    // Remember that user has dismissed the tooltip
    localStorage.setItem('hasSeenEditTooltip', 'true');
  };

  React.useEffect(() => {

    setTimeout(() => {
      setDrawerOpen(true)
    }, 1000)

    async function fetchData() {
      setIsLoading(true);

      let pdfData = null;
      try {
        debugLogs && console.log('httpOnCreatePdf')
        const res: any = await httpOnCreatePdf(params.latexB62Id!, userId)
        pdfData = res.data;
        setPdfData(pdfData);

        // Get tailored resume data for the timestamp
        const tailoredRes: any = await httpOnGetTailored(params.tailoredB62Id!, userId);
        console.log('Tailored resume data:', tailoredRes.data);  // Debug log
        setLatexUpdatedAt(formatUpdatedAt(tailoredRes.data.updated_at));
        setHasPreviousVersion(!!tailoredRes.data.previous_version_id);  // Fix: use correct property name
        console.log('Has previous version:', !!tailoredRes.data.previous_version_id);  // Debug log

        setTimeout(() => {
          setIsLoading(false);
        }, 1000) // let pdf iframe to be rendered

      } catch (e) {
        const errorMsg = 'We were unable to generate your final PDF resume. Please try again in a moment, and if the issue persists, contact our support team for assistance.';
        displayError(errorMsg, formatErrorMessageHtml(errorMsg, e))
        setIsLoading(false);
        return;
      }

      setErrorMessage('');
    }

    fetchData();

    const fetchOriginalResume = httpOnGetResume(params.resumeB62Id!, userId)
      .then((res: any) => {
        const resume = res.data;
        setFileName(resume.file_name);
        setResumeUpdatedAt(formatUpdatedAt(resume.updated_at));
      })
      .catch((e: any) => {
        // If original resume fetch fails with 404, use tailored resume as base resume
        if (e?.status === 404) {
          return httpOnGetTailored(params.tailoredB62Id!, userId)
            .then((res: any) => {
              const resume = res.data;
              setFileName(resume.resume_details?.name);
              setResumeUpdatedAt(formatUpdatedAt(resume.created_at));
            })
            .catch((e) => {
              const errorMsg = 'We were unable to load original resume data. This could be due to a temporary connection issue. Please refresh the page and try again. If the problem persists, contact our support team for assistance.';
              displayError(errorMsg, formatErrorMessageHtml(errorMsg, e));
            });
        }
        // For non-404 errors, we can silently fail as this is not critical for PDF viewing
      });

    httpOnGetJobDescription(params.jobB62Id!, userId).then((res: any) => {
      const jb = res.data;
      let title = jb?.analysis?.job_title?.title || jb?.job_title_details?.title || jb?.job_details?.titles?.[0];
      setJobTitle(title);
      setJobCompany(jb?.job_details?.company || jb?.job_title_details?.industry);
    }).catch((e: any) => {})

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.latexB62Id]);

  const displayError = (errorMessage: string, errorMessageHtml: string) => {
    dispatch(onAlertShowAction({ type: 'error', message: errorMessage }));
    setErrorMessage(errorMessageHtml);
    setGenerateInProgress(false);
  }

  const removeResume = async (resumeB62Id: string) => {
    setRemoveResumeInProgress(true);
    try {
      await httpOnDeleteResume(resumeB62Id, userId)
    } catch (e) {
      setRemoveResumeInProgress(false);
      return;
    }
    setRemoveResumeInProgress(false);
    navigate(`/app`)
  }

  const editResume = (resumeB62Id: string)  =>  navigate(`/app/${params.resumeB62Id}/edit`);

  const editTailoredResume = ()  =>  navigate(`/app/${params.resumeB62Id}/job/${params.jobB62Id}/tailored-resume/${params.tailoredB62Id}`);

  const downloadResume = async () => {
    setIsLoadingDownload(true);

    let pdfData = null;
    try {
      debugLogs && console.log('httpOnCreatePdf')
      const res: any = await httpOnCreatePdf(params.latexB62Id!, userId, false)
      pdfData = res.data;

      try {
        downloadResumeFromPresignedUrl(pdfData);
      

        setIsLoadingDownload(false);

        setTimeout(() => { feedbackPopupRef?.current?.open(); }, 1000);
      }
      catch (e) {
        console.log('download button error:', e)
      }

    } catch (e) {
      const errorMsg = 'We were unable to download your PDF resume. This could be due to a temporary connection issue. Please try again in a few moments, and if the issue persists, contact our support team for assistance.';
      displayError(errorMsg, formatErrorMessageHtml(errorMsg, e))

      setIsLoadingDownload(false);
      return;
    }

    setErrorMessage('');

  }

  const downloadResumeFromPresignedUrl = (pdfData: any) => {
    const url = pdfData['presigned_url'];
    const fileName = pdfData['pdf_key'];
    const link = document.createElement('a');
    link.href = url;
    // link.setAttribute("target", "_blank");
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    
    document.body.removeChild(link);

  }

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const [isScoresDialogIsOpen, setIsScoresDialogIsOpen] = React.useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const navigateToResourcesView = (resumeB62Id: string)  =>  navigate(`/app/${params.resumeB62Id}/folder`);


  const feedbackPopupRef = useRef<{open: () => void }>(null);

  return (
    <Box pt={3}>

      <Box sx={{'display': 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }} pb={3}>

        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', width: '100%', flexWrap: 'wrap' }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1,
            minWidth: { xs: '100%', md: 'auto' },
            mb: { xs: 2, md: 0 },
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1.5),
            boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.08)'
          }}>
            <ResumeTitle fileName={fileName} resumeUpdatedAt={resumeUpdatedAt} />
            { removeResumeInProgress && <CircularProgress sx={{
              marginTop: '10px',
              width: '20px !important', height: '20px !important'}}/>
            }
            <Box sx={{'display': 'flex'}}>
              <Tooltip title={'Edit original resume text.'} placement="top" arrow>
                <span>
                <IconButton color={'primary'}
                  onClick={() => editResume(resumeB62Id)} disabled={generateInProgress || removeResumeInProgress || !params.resumeB62Id}>
                  <EditNote/>
                </IconButton>
                </span>
              </Tooltip>
            </Box>
          </Box>

          <Box sx={{ 
            background: hasPreviousVersion
              ? `linear-gradient(135deg, ${alpha(theme.palette.secondary.main, 0.9)} 0%, ${alpha(theme.palette.info.main, 0.9)} 100%)`
              : alpha(theme.palette.secondary.main, 0.9),
            backdropFilter: 'blur(8px)',
            border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
            padding: theme.spacing(2),
            borderRadius: theme.spacing(1),
            flexGrow: 1,
            position: 'relative',
            maxWidth: { xs: '100%', md: '400px' },
            boxShadow: `0 8px 32px ${alpha(theme.palette.common.black, 0.15)}`,
            '&:hover': {
              background: hasPreviousVersion
                ? `linear-gradient(135deg, ${alpha(theme.palette.secondary.main, 0.95)} 0%, ${alpha(theme.palette.info.main, 0.95)} 100%)`
                : alpha(theme.palette.secondary.main, 0.95),
              boxShadow: `0 8px 32px ${alpha(theme.palette.common.black, 0.2)}`,
              transition: 'all 0.3s ease-in-out'
            }
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', overflow: 'hidden' }}>
                <Tooltip title={jobTitle} placement="top" arrow>
                  <Typography variant="subtitle1" sx={{ 
                    color: 'white',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '70%'
                  }}>{jobTitle}</Typography>
                </Tooltip>
                {!jobTitle && <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100px', bgcolor: 'rgba(255, 255, 255, 0.1)' }} />}
                <Typography sx={{ mx: 1, color: 'white', flexShrink: 0 }}>&#x2022;</Typography>
                <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', flexShrink: 0 }}>{jobCompany}</Typography>
                {!jobCompany && <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100px', bgcolor: 'rgba(255, 255, 255, 0.1)' }} />}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                Tailored at <Box component="span" sx={{ fontWeight: 'bold', color: 'white' }}>{latexUpdatedAt}</Box>
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="caption" sx={{ 
                  color: 'rgba(255, 255, 255, 0.5)', 
                  fontSize: '0.7rem',
                  marginRight: 1
                }}>
                  {params.tailoredB62Id}
                </Typography>
                <Tooltip title={'View previously added job descriptions and resumes'} placement="top" arrow>
                  <span>
                    <IconButton 
                      color={'primary'} 
                      onClick={() => {navigateToResourcesView(params.resumeB62Id!)}} 
                      disabled={generateInProgress || removeResumeInProgress}
                      sx={{ color: 'white' }}
                    >
                      <GridViewSharpIcon/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            </Box>

            <Tooltip title="Delete Tailored Resume" placement="top" arrow>
              <IconButton 
                onClick={() => setRemoveResumeDialogOpen(true)} 
                disabled={generateInProgress || removeResumeInProgress}
                size="small"
                sx={{ 
                  position: 'absolute',
                  top: -12,
                  right: { xs: 'unset', md: -12 },
                  left: { xs: -12, md: 'unset' },
                  backgroundColor: 'white',
                  border: '2px solid',
                  borderColor: theme.palette.grey[300],
                  padding: '4px',
                  '&:hover': {
                    backgroundColor: theme.palette.error.main,
                    borderColor: theme.palette.error.main,
                    '& svg': {
                      color: 'white'
                    }
                  },
                  '& svg': {
                    color: theme.palette.grey[700],
                    fontSize: '1.2rem'
                  }
                }}
              >
                <Cancel />
              </IconButton>
            </Tooltip>
          </Box>

          <Box sx={{ 
            display: 'flex', 
            alignItems: 'flex-end',
            ml: { xs: 0, md: 4 },
            flexShrink: 0,
            gap: 2,
            flexWrap: 'wrap'
          }}>
            <Box sx={{ 
              display: 'flex',
              gap: 2,
              flexShrink: 0
            }}>
              <Box sx={{ mt: '16px' }}>
                <Tooltip
                  title={
                    <>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                         width: '240px' 
                         }}>
                        <InfoIcon style={{ marginRight: '8px' }} />
                        <span>
                          Edit or view changes proposed by ResumeFactory
                        </span>
                        <IconButton onClick={handleTooltipClose} size="small" sx={{position: 'absolute', right: 0, color: 'white'}}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </>
                  }
                  open={isEditButtonTooltipOpen}
                  onClose={() => setIsEditButtonTooltipOpen(false)}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  arrow={true}
                  placement="top"
                >
                  <Button
                    component="label"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    startIcon={<Edit />}
                    disabled={isLoading}
                    onClick={() => editTailoredResume()}
                  >
                    Edit
                  </Button>
                </Tooltip>
              </Box>

              <Box pt={2}>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<Download />}
                  disabled={isLoading || isLoadingDownload}
                  onClick={() => downloadResume()}
                  sx={{ textAlign: 'center', minWidth: 'unset' }}
                >
                  Download PDF
                </Button>
              </Box>
            </Box>

            <Box pt={2} sx={{'display': {xs: 'none', sm: 'none', md: 'flex'}}}>
              <ButtonGroup variant="contained">
                <Button
                  component="label"
                  role={undefined}
                  tabIndex={-1}
                  startIcon={ isDrawerOpen ? <VisibilityOff /> : <Speed /> }
                  disabled={isLoading}
                  onClick={() => toggleDrawer()}
                  sx={{ textAlign: 'center', minWidth: 'unset' }}
                >
                  Relevancy score
                </Button>
                <Button
                  tabIndex={-1}
                  endIcon={ <OpenInFull/> }
                  disabled={isLoading}
                  onClick={() => setIsScoresDialogIsOpen(true)}
                  sx={{ minWidth: '40px', padding: '6px 0' }}
                >
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        </Box>
      </Box>

      { isLoading && <Box py={2}>
        <LinearProgress sx={{
          height: '4px',
          borderRadius: '2px'
        }}/>
      </Box>}

      <Box pb={3}>

        <Box sx={{ display: 'flex', minHeight: {xs: 'unset', md: '100vh'}, flexDirection: { xs: 'column-reverse', md: 'unset'} }} >

          <Main open={isDrawerOpen} sx={{ display: 'flex', minHeight: {xs: '100vh', md: '100%'}, marginTop: {xs: 1, sm: 1, md: 0 } }} >

              <Box sx={{flexGrow: 1, 'width': '100%'}}>
              {pdfData && (
                          <iframe
                            title="PDF Document Viewer" 
                            style={{ width: '100%', height: '100%', border: 'none', borderRadius: theme.spacing(1) }}
                            src={pdfData['presigned_url']}
                          />
                        )}

                <Box sx={{flexGrow: 1, 'width': '100%', display: isLoading ? 'block' : 'none' }}>

                  <Box pt={1} pb={1}>
                    <Skeleton variant="rectangular" width={'100%'} height={40} />
                  </Box>
                  <Box pt={1} pb={1}>
                    <Skeleton variant="rectangular" width={'100%'} height={400} />
                  </Box>
                </Box>

                { errorMessage &&
                  <Box pb={2} pt={1}>
                    <Alert severity="error"><div dangerouslySetInnerHTML={{ __html: errorMessage }} /></Alert>
                  </Box> 
                }
                
              </Box>

          </Main>

          <Drawer
              sx={{
                width: { drawerWidth },
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: { sm: 'unset', md: drawerWidth - drawerSpaceDividerWidth  },
                  position: 'relative',
                  marginLeft: { sm: 0, md: `${drawerSpaceDividerWidth}px` },
                  [theme.breakpoints.down('md')]: {
                    visibility: 'visible !important',
                    transform: 'none !important',
                  },
                  borderRadius: theme.spacing(1),
                  height: 'unset'
                },
              }}
              variant="persistent"
              anchor="right"
              open={isDrawerOpen}
          >

            <Box>
              <ComparisonScores tailoredB62Id={params.tailoredB62Id!} onClose={() => {setDrawerOpen(false)}}
                isDialogOpen={isScoresDialogIsOpen} onChangeDialogOpen={(isOpen) => {setIsScoresDialogIsOpen(isOpen)}}
              ></ComparisonScores>
            </Box>
          </Drawer>

        </Box>

        <Box sx={{
          position: 'fixed',
          right: '62px',
          bottom: '57px',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          alignItems: 'flex-end',
          zIndex: 100000
        }}>
          <Box>
            <a href="https://www.buymeacoffee.com/resumefactory.ai" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              <img 
                src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=resumefactory.ai&button_colour=BD5FFF&font_colour=ffffff&font_family=Cookie&outline_colour=000000&coffee_colour=FFDD00"
                alt="Buy me a coffee"
                style={{
                  height: '36px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease-in-out',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                }}
                onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
              />
            </a>
          </Box>
        </Box>

      </Box>

      <FeedbackSidePopup latexB62Id={params.latexB62Id!} ref={feedbackPopupRef}></FeedbackSidePopup>

      <ConfirmDialogBox
        title="Please confirm"
        message="Please confirm you want to delete this resume!"
        open={removeResumeDialogOpen}
        primaryButton={{ label: 'Delete', onClick: () => {removeResume(params.resumeB62Id!);setRemoveResumeDialogOpen(false);} }}
        secondaryButton={{ label: 'Cancel', onClick: () => setRemoveResumeDialogOpen(false) }}
        fullWidth
        onClose={() => setRemoveResumeDialogOpen(false)}
      />

    </Box>
  );
}
