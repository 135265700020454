import React from 'react';
import { Box } from '@mui/material';
import PrimaryTextInput from '../PrimaryTextInput';
import { Controller } from 'react-hook-form';

interface Props {
  dateIsRange: boolean;
  required?: boolean;
  formPath: string; // e.g. `awards.${index}.date_or_dates`
  control: any;
    // ...props;
}

const DateOrDates: React.FC<Props> = ({ dateIsRange, required, formPath, control, ...props }) => {
  // Transform empty strings to null and ensure valid strings
  const transformValue = (event: any): string => {
    const value = typeof event === 'string' ? event : event?.target?.value;
    // Return empty string instead of null to ensure we always have a string
    return value?.trim() || '';
  };

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Box sx={{ flex: 1 }}>
        <Controller
          name={`${formPath}.0`}
          control={control}
          defaultValue=""
          render={({ field: { onChange, onBlur, value } }) => (
            <PrimaryTextInput
              name={dateIsRange ? "Start Date" : "Date"}
              label={dateIsRange ? "Start Date" : "Date"}
              onChange={(event: any) => onChange(transformValue(event))}
              value={value || ''}
              required={required}
              multiline={true}
            />
          )}
        />
      </Box>

      {dateIsRange && 
        <Box sx={{ flex: 1 }}>
          <Controller
            name={`${formPath}.1`}
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value } }) => (
              <PrimaryTextInput
                name="End Date"
                label="End Date"
                onChange={(event: any) => onChange(transformValue(event))}
                value={value || ''}
                required={required}
                multiline={true}
              />
            )}
          />
        </Box>
      }
    </Box>
  );
};

export default DateOrDates;
