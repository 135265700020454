import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { httpOnGoogleAuth, httpOnLinkGoogle } from '../../Services/Http';
import useUser from '../../Hooks/useUser';
import { useDispatch } from 'react-redux';
import { onUserSaveAction } from '../../Redux';
import { Box, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getStoredTokens } from '../../Services/Aws/Session';
import { testRefreshFlow } from '../../Services/Aws/SessionUtils';

interface GoogleAuthButtonProps {
    mode: 'signin' | 'link';
    onSuccess?: () => void;
    onError?: (error: any) => void;
}

export const GoogleAuthButton: React.FC<GoogleAuthButtonProps> = ({
    mode,
    onSuccess,
    onError
}) => {
    const { userId } = useUser();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [showExistingUserDialog, setShowExistingUserDialog] = React.useState(false);

    const handleGoogleSuccess = async (credentialResponse: any) => {
        setLoading(true);
        try {
            if (mode === 'signin') {
                const response = await httpOnGoogleAuth(credentialResponse.credential);
                console.log('Google auth successful:', response);
                if (response.data?.user) {
                    const userImage = response.data.user.pictureUrl && response.data.user.pictureUrl.trim() ? response.data.user.pictureUrl : '';
                    dispatch(onUserSaveAction({
                        id: response.data.user.userId,
                        email: response.data.user.email,
                        name: response.data.user.name || response.data.user.email.split('@')[0],
                        role: 'USER',
                        accessToken: response.data.access_token,
                        image: userImage,
                        isAdmin: false
                    }));
                    console.log('User data with picture:', response.data.user);
                    
                    // Test refresh token flow immediately after login
                    console.log('🧪 Testing refresh token flow immediately after Google login...');
                    setTimeout(() => {
                        testRefreshFlow(getStoredTokens, localStorage.setItem.bind(localStorage))
                            .then(success => {
                                console.log(`🔄 Immediate refresh token test ${success ? 'succeeded' : 'failed'}`);
                            })
                            .catch(error => {
                                console.error('❌ Error during immediate refresh token test:', error);
                            });
                    }, 2000); // Wait 2 seconds to ensure tokens are properly stored
                    
                    onSuccess?.();
                } else {
                    throw new Error('Invalid response format');
                }
            } else {
                if (!userId) return;
                await httpOnLinkGoogle(credentialResponse.credential, userId);
                onSuccess?.();
            }
        } catch (error: any) {
            console.error('Google auth error:', error);
            // Check if this is an existing user case (422 status)
            if (error.response?.status === 422) {
                setShowExistingUserDialog(true);
            } else {
                onError?.(error);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleError = () => {
        console.error('Google sign-in failed');
        onError?.(new Error('Google sign-in failed'));
    };

    const handleLoginClick = () => {
        setShowExistingUserDialog(false);
        navigate('/login');
    };

    return (
        <>
            <Box sx={{ position: 'relative' }}>
                <GoogleLogin
                    onSuccess={handleGoogleSuccess}
                    onError={handleGoogleError}
                    theme="filled_black"
                    shape="rectangular"
                    text={mode === 'signin' ? 'signin_with' : 'continue_with'}
                    useOneTap={false}
                    type="standard"
                    size="large"
                />
                {loading && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            borderRadius: 1,
                            zIndex: 1,
                            cursor: 'not-allowed'
                        }}
                    >
                        <CircularProgress size={24} />
                    </Box>
                )}
            </Box>

            <Dialog 
                open={showExistingUserDialog} 
                onClose={() => setShowExistingUserDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Existing Account Found</DialogTitle>
                <DialogContent>
                    <Typography>
                        We found an existing account with this email address. Please log in with your email and password first, then you can link your Google account from your profile settings.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowExistingUserDialog(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleLoginClick} variant="contained" color="primary">
                        Go to Login
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}; 