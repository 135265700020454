import { 
  Box, 
  IconButton, 
  LinearProgress, 
  Tooltip, 
  Typography, 
  Card, 
  CardContent, 
  Grid, 
  Divider, 
  Button,
  lighten,
  Alert,
  darken,
  alpha,
  ToggleButton,
  ToggleButtonGroup,
  FormControlLabel,
  Switch,
  CircularProgress
} from "@mui/material";
import React from "react";
import { ArrowBack, DeleteForever, Edit, Visibility, AccountTree, GridView } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { 
  httpOnGetResume, 
  httpOnDeleteResume, 
  httpOnGetJobDescriptions, 
  httpOnGetTailoreds, 
  httpOnGetTailoredResumeThumbnail, 
  httpOnDeleteTailoredResume, 
  formatErrorMessageHtml
} from "../Services/Http";
import useUser from "../Hooks/useUser";
import theme from "../theme";
import { useDispatch } from "react-redux";
import { onAlertShowAction } from "../Redux/Alert/Actions";
import { formatUpdatedAt } from "../Services/Util/dateFormat";
import ResumeTitle from "../Components/ResumeTitle";
import ConfirmDialogBox from "../Components/ConfirmDialogBox";
import ResumeThumbnail from "../Components/ResumeThumbnail";


import ImgLogo from '../Assets/logo-net-dark.png';
import ResumeCard from "../Components/ResumeCard";
import FeedbackSidePopup from "../Components/FeedbackSidePopup";
import { getFeatureFlag, setFeatureFlag } from "../utils/featureFlags";


interface JobDescription {
  base62_id: string;
  company: string;
  created_at: string;
  has_ideal_resume: boolean;
  ideal_resume_status: string;
  status: string;
  title: string;
  updated_at: string;
  body: string;
}

interface TailoredResume {
  base62_id: string;
  comparison_status: string;
  created_at: string;
  has_tailored_comparison: boolean;
  has_untailored_comparison: boolean;
  job_base62_id: string;
  latex_resume_base62_id: string;
  latex_resume_updated_at: string;
  pdf_success: boolean;
  resume_base62_id: string;
  s3_path: string;
  status: string;
  template_base62_id: string;
  template_name: string;
  updated_at: string;
  previous_version_id: string | null;
  previous_base62_id: string | null;
}

interface ICard {
  resume: {
    file_name: string;
    updated_at: string;
  };
  job_description: JobDescription;
  tailored_resume: TailoredResume;
  isDeleting?: boolean;
}

interface TailoredResumeNode {
  card: ICard;
  children: TailoredResumeNode[];
  level: number;
}

export default function ResumeFolder() {

  const [fileName, setFileName] = React.useState<string>("");
  const [fileUpdatedAt, setFileUpdatedAt] = React.useState<string>("");

  const [errorMessage, setErrorMessage] = React.useState<string>("");

  const [cards, setCards] = React.useState<ICard[]>([]);

  const [saveInProgress, setSaveInProgress] = React.useState<boolean>(false);
  // Removed unused state variables: generateCompleted, generationProgress, resumeData

  const [removeResumeInProgress, setRemoveResumeInProgress] = React.useState<boolean>(false);

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isLoadingJobDescriptions, setIsLoadingJobDescriptions] = React.useState<boolean>(true);
  const [isLoadingTailoreds, setIsLoadingTailoreds] = React.useState<boolean>(true);

  const [tailoredResumes, setTailoredResumes] = React.useState<TailoredResume[]>([]);
  const [jobDescriptions, setJobDescriptions] = React.useState<JobDescription[]>([]);

  const [thumbnails, setThumbnails] = React.useState<{ [key: string]: string }>({});

  const [cardToRemove, setCardToRemove] = React.useState<ICard>();

  const [viewMode, setViewMode] = React.useState<'tree' | 'grid'>('tree');
  const [previousViewMode, setPreviousViewMode] = React.useState<'tree' | 'grid'>('tree');
  const [isSimplifiedView, setIsSimplifiedView] = React.useState<boolean>(() => {
    // Initialize from localStorage, defaulting to grid view if enabled
    const simplified = getFeatureFlag('simplifiedResumeView');
    if (simplified) {
      setViewMode('grid');
    }
    return simplified;
  });

  const navigate = useNavigate();
  const params = useParams();
  const { userId } = useUser();

  const dispatch = useDispatch();

  const displayError = React.useCallback((errorMessage: string, errorMessageHtml: string) => {
    dispatch(onAlertShowAction({ type: 'error', message: errorMessage }));
    setErrorMessage(errorMessageHtml);
    setSaveInProgress(false);
  }, [dispatch]);

  const loadThumbnail = React.useCallback((card: ICard) => {
    const tailoredB62Id = card.tailored_resume.base62_id;

    httpOnGetTailoredResumeThumbnail(tailoredB62Id, userId)
      .then((data: any) => {
        setThumbnails((prevThumbnails) => ({
          ...prevThumbnails,
          [tailoredB62Id]: data.pdf_thumbnail,
        }));
      })
      .catch((error: any) => {
        console.error(`Failed to load thumbnail for ${tailoredB62Id}:`, error);
        // Optionally handle the error, e.g., set a default thumbnail
      });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const getThumbnailUrl = React.useCallback((card: ICard): string | undefined => {
    const thumbnailB62Id = card.tailored_resume.base62_id;

    if (thumbnailB62Id in thumbnails) {
      return thumbnails[thumbnailB62Id];
    } else {
      loadThumbnail(card);
      return undefined;
    }
  }, [thumbnails, loadThumbnail]);

  React.useEffect(() => {
    setIsLoading(true);
    httpOnGetResume(params.resumeB62Id!, userId)
      .then((res: any) => {
        const resume = res.data;
        setFileName(resume.file_name);
        setFileUpdatedAt(formatUpdatedAt(resume.updated_at));
        // setResumeData(resume.resume_details); // Removed as it's unused
        setIsLoading(false);
      })
      .catch((e: any) => {
        setIsLoading(false);
        const errorMessage = "Failed to load resume data";
        displayError(errorMessage, formatErrorMessageHtml(errorMessage, e));
      })

    setIsLoadingJobDescriptions(true);
    httpOnGetJobDescriptions(userId)
      .then((res: any) => {
        const jobDescriptions = res?.data?.results || [];
        setJobDescriptions(jobDescriptions);
        setIsLoadingJobDescriptions(false);
      })
      .catch((e: any) => {
        setIsLoadingJobDescriptions(false);
        const errorMessage = "Failed to load job descriptions";
        displayError(errorMessage, formatErrorMessageHtml(errorMessage, e));
      })

    setIsLoadingTailoreds(true);
    httpOnGetTailoreds(userId)
      .then((res: any) => {
        const tailoredResumes = res?.data?.results || [];
        setTailoredResumes(tailoredResumes);
        setIsLoadingTailoreds(false);
      })
      .catch((e: any) => {
        setIsLoadingTailoreds(false);
        const errorMessage = "Failed to load tailored resumes";
        displayError(errorMessage, formatErrorMessageHtml(errorMessage, e));
      })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.resumeB62Id, displayError, userId]);

  // Helper function to get all related resumes
  const getRelatedTailoredResumes = React.useCallback((allResumes: TailoredResume[], originalResumeId: string): TailoredResume[] => {
    const findAllDescendants = (parentId: string): TailoredResume[] => {
      const children = allResumes.filter(resume => resume.previous_base62_id === parentId);
      const descendants = children.flatMap(child => findAllDescendants(child.base62_id));
      return [...children, ...descendants];
    };

    const originalResumes = allResumes.filter(resume => 
      resume.resume_base62_id === originalResumeId && 
      resume.previous_base62_id === null
    );

    const allDescendants = originalResumes.flatMap(original => 
      [original, ...findAllDescendants(original.base62_id)]
    );

    // If simplified view is enabled, only return the leaf nodes
    if (isSimplifiedView) {
      // A resume is a leaf if no other resume has it as a parent
      return allDescendants.filter(resume => 
        !allDescendants.some(other => other.previous_base62_id === resume.base62_id)
      );
    }

    return allDescendants;
  }, [isSimplifiedView]);

  // Helper function to get the original resume's base62_id
  const getParentResumeId = (tailoredResume: TailoredResume): string => {
    // Always use the resume_base62_id which points to the original resume
    // This ensures consistent URL structure regardless of the tailored resume hierarchy
    return tailoredResume.resume_base62_id;
  };

  React.useEffect(() => {
    if (!(jobDescriptions.length && tailoredResumes.length && fileName)) {
      return;
    }

    // Get all related tailored resumes including originals and their descendants
    const relatedTailoredResumes = getRelatedTailoredResumes(tailoredResumes, params.resumeB62Id!);

    const generatedCards = relatedTailoredResumes
      .map((tailoredResume) => {
        const jobDescription = jobDescriptions.find((jd) => jd.base62_id === tailoredResume.job_base62_id);
        return {
          resume: {
            file_name: fileName,
            updated_at: fileUpdatedAt,
          },
          job_description: jobDescription,
          tailored_resume: tailoredResume,
        };
      })
      .filter((card): card is ICard => card.job_description !== undefined)
      .sort((a: ICard, b: ICard) => new Date(b.tailored_resume.updated_at).getTime() - new Date(a.tailored_resume.updated_at).getTime());

    setCards(generatedCards);

    generatedCards.forEach((card) => getThumbnailUrl(card));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobDescriptions, tailoredResumes, fileName, fileUpdatedAt, getThumbnailUrl, params.resumeB62Id, isSimplifiedView]);

  const removeResume = async (resumeB62Id: string) => {
    setRemoveResumeInProgress(true);
    try {
      await httpOnDeleteResume(resumeB62Id, userId)
    } catch (e: any) {
      setRemoveResumeInProgress(false);
      const errorMessage = "Failed to delete resume";
      displayError(errorMessage, formatErrorMessageHtml(errorMessage, e));
      return;
    }
    setRemoveResumeInProgress(false);
    navigate(`/app`)
  }

  const backToHome = () => navigate(`/app/${params.resumeB62Id}`);

  const navigateToTailoredResume = (card: ICard) => {
    const parentResumeId = getParentResumeId(card.tailored_resume);
    navigate(`/app/${parentResumeId}/job/${card.job_description.base62_id}/tailored-resume/${card.tailored_resume.base62_id}`);
  };

  const navigateToPdfResume = (card: ICard) => {
    const parentResumeId = getParentResumeId(card.tailored_resume);
    navigate(`/app/${parentResumeId}/job/${card.job_description.base62_id}/tailored-resume/${card.tailored_resume.base62_id}/latex/${card.tailored_resume.latex_resume_base62_id}`);
  };

  const deleteTailoredResume = (card: ICard) => {
    const tailoredResumeB62Id = card.tailored_resume.base62_id;
    card.isDeleting = true;
    setCards((prevCards) => [...prevCards]);
    httpOnDeleteTailoredResume(tailoredResumeB62Id, userId)
      .then(() => {
        card.isDeleting = false;
        setCards((prevCards) => prevCards.filter((c) => c.tailored_resume.base62_id !== tailoredResumeB62Id));
      })
      .catch((error: any) => {
        card.isDeleting = false;
        setCards((prevCards) => [...prevCards]);
        const errorMessage = "Failed to delete tailored resume";
        displayError(errorMessage, formatErrorMessageHtml(errorMessage, error));
      })
  }

  function isSomethingInProgress() {
    return (isLoading || saveInProgress || isLoadingJobDescriptions || isLoadingTailoreds);
  }

  const buildTailoredResumeTree = (cards: ICard[]): TailoredResumeNode[] => {
    // Find root level cards (direct children of original resume)
    const rootLevelCards = cards.filter(card => 
      card.tailored_resume.resume_base62_id === params.resumeB62Id &&
      !card.tailored_resume.previous_base62_id
    );

    // Recursive function to build tree
    const buildNode = (card: ICard, level: number): TailoredResumeNode => {
      const children = cards
        .filter(c => c.tailored_resume.previous_base62_id === card.tailored_resume.base62_id)
        .map(childCard => buildNode(childCard, level + 1));

      return {
        card,
        children,
        level
      };
    };

    // Build tree starting from root level cards
    return rootLevelCards.map(card => buildNode(card, 0));
  };

  const renderTreeNode = (node: TailoredResumeNode) => {
    return (
      <Box 
        key={node.card.tailored_resume.base62_id}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: theme.spacing(4),
          mb: theme.spacing(4),
          position: 'relative'
        }}
      >
        <Box sx={{ 
          position: 'relative',
          minWidth: '370px', // Width of the card
          '&::after': node.children.length > 0 ? {
            content: '""',
            position: 'absolute',
            right: '-16px',
            top: '310px',
            width: theme.spacing(4),
            height: '2px',
            backgroundColor: alpha(theme.palette.primary.main, 0.3),
            transform: 'translateY(-50%)',
            zIndex: 0
          } : {}
        }}>
          {node.card.isDeleting && 
            <Box py={2}>
              <LinearProgress 
                sx={{ 
                  height: '4px',
                  borderRadius: '2px',
                  borderTopRightRadius: '16px', 
                  borderTopLeftRadius: '16px' 
                }}
              />
            </Box>
          }
          <Card sx={{ 
            borderRadius: theme.spacing(1.5),
            borderLeft: node.level > 0 ? `4px solid ${alpha(theme.palette.primary.main, 0.3)}` : 'none',
            width: '370px',
            height: '620px',
            position: 'relative',
            zIndex: 1
          }}>
            <CardContent sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
              <Box pb={1}>
                {node.card.tailored_resume?.base62_id && (
                  <>
                    <Box sx={{ 
                      background: node.card.tailored_resume.previous_base62_id
                        ? `linear-gradient(135deg, ${alpha(theme.palette.secondary.main, 0.9)}, ${alpha(theme.palette.info.main, 0.9)})`
                        : alpha(theme.palette.secondary.main, 0.9),
                      backdropFilter: 'blur(8px)',
                      border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
                      padding: theme.spacing(2),
                      borderRadius: theme.spacing(1.5),
                      boxShadow: `0 8px 32px ${alpha(theme.palette.common.black, 0.15)}`,
                      '&:hover': {
                        background: node.card.tailored_resume.previous_base62_id
                          ? `linear-gradient(135deg, ${alpha(theme.palette.secondary.main, 0.95)}, ${alpha(theme.palette.info.main, 0.95)})`
                          : alpha(theme.palette.secondary.main, 0.95),
                        boxShadow: `0 8px 32px ${alpha(theme.palette.common.black, 0.2)}`,
                        transition: 'all 0.3s ease-in-out'
                      }
                    }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }} pb={1}>
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', overflow: 'hidden' }}>
                          <Tooltip title={node.card.job_description?.title} placement="top" arrow>
                            <Typography variant="subtitle1" sx={{ 
                              color: 'white',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: '70%'
                            }}>{node.card.job_description?.title}</Typography>
                          </Tooltip>
                          <Typography sx={{ mx: 1, color: 'white', flexShrink: 0 }}>&#x2022;</Typography>
                          <Tooltip title={node.card.job_description?.company || '-'} placement="top" arrow>
                            <Typography sx={{ 
                              color: 'white',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: '30%'
                            }}>{node.card.job_description?.company || '-'}</Typography>
                          </Tooltip>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                          Tailored at <Box component="span" sx={{ fontWeight: 'bold', color: 'white' }}>
                            {formatUpdatedAt(node.card.tailored_resume.updated_at)}
                          </Box>
                        </Typography>
                        <Typography variant="caption" sx={{ 
                          color: 'rgba(255, 255, 255, 0.5)', 
                          fontSize: '0.7rem',
                          alignSelf: 'flex-end'
                        }}>
                          {node.card.tailored_resume.base62_id}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, pt: 2 }}>
                <Box sx={{ 
                  flexGrow: 1,
                  pl: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  maxWidth: 'calc(100% - 48px)'
                }}>
                  <ResumeThumbnail 
                    thumbnailUrl={thumbnails?.[node.card.tailored_resume?.base62_id]}
                    onClick={() => node.card.tailored_resume.latex_resume_base62_id ? navigateToPdfResume(node.card) : navigateToTailoredResume(node.card)}
                  />
                </Box>

                <Box pb={1} pt={1} sx={{ 
                  alignSelf: 'end',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  ml: 2,
                  width: '48px'
                }}>
                  <Tooltip title={node.card.tailored_resume.latex_resume_base62_id ? "View tailored resume" : "Please vizit edit page to generate the PDF"} placement="top" arrow>
                    <span>
                      <IconButton 
                        color="primary" 
                        onClick={() => node.card.tailored_resume.latex_resume_base62_id ? navigateToPdfResume(node.card) : null}
                        disabled={!node.card.tailored_resume.latex_resume_base62_id}
                      >
                        <Visibility />
                      </IconButton>
                    </span>
                  </Tooltip>
                  
                  <Tooltip title="Edit tailored resume" placement="top" arrow>
                    <span>
                      <IconButton color="primary" onClick={() => navigateToTailoredResume(node.card)}>
                        <Edit />
                      </IconButton>
                    </span>
                  </Tooltip>
                  
                  <Tooltip title="Delete tailored resume" placement="top" arrow>
                    <span>
                      <IconButton 
                        color="error" 
                        onClick={() => setCardToRemove(node.card)} 
                        disabled={node.card.isDeleting}
                      >
                        <DeleteForever />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
              </Box>

              {node.card.job_description?.body && (
                <Box pt={2}>
                  <Typography sx={{
                    padding: theme.spacing(2),
                    paddingLeft: theme.spacing(3),
                    borderLeft: `4px solid ${alpha(theme.palette.primary.main, 0.5)}`,
                    borderRadius: 0,
                    fontSize: '12px',
                    backgroundColor: alpha(theme.palette.primary.main, 0.03),
                    fontStyle: 'italic'
                  }}>{node.card.job_description?.body}</Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Box>

        {node.children.length > 0 && (
          <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(4),
            position: 'relative'
          }}>
            {node.children.map((child) => (
              <Box key={child.card.tailored_resume.base62_id} sx={{
                position: 'relative',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  left: '-16px',
                  top: '310px',
                  width: theme.spacing(4),
                  height: '2px',
                  backgroundColor: alpha(theme.palette.primary.main, 0.3),
                  transform: 'translateY(-50%)',
                  zIndex: 0
                }
              }}>
                {renderTreeNode(child)}
              </Box>
            ))}
          </Box>
        )}
      </Box>
    );
  };

  const hasNestedTailoredResumes = React.useMemo(() => {
    return cards.some(card => card.tailored_resume.previous_base62_id !== null);
  }, [cards]);

  React.useEffect(() => {
    // Only force grid view if:
    // 1. Cards have loaded (not empty array)
    // 2. There are no nested tailored resumes
    // 3. Current view mode is tree
    if (cards.length > 0 && !hasNestedTailoredResumes && viewMode === 'tree') {
      setViewMode('grid');
    }
  }, [cards, hasNestedTailoredResumes, viewMode]);

  // Update view mode when simplified view changes
  React.useEffect(() => {
    if (isSimplifiedView) {
      setViewMode('grid');
    }
  }, [isSimplifiedView]);

  // Handle simplified view toggle
  const handleSimplifiedViewToggle = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setFeatureFlag('simplifiedResumeView', newValue);
    setIsSimplifiedView(newValue);
    if (newValue) {
      // Store current view mode before switching to grid
      setPreviousViewMode(viewMode);
      setViewMode('grid');
    } else {
      // Restore previous view mode when disabling simplified view
      setViewMode(previousViewMode);
    }
  }, [viewMode, previousViewMode]);

  // Handle view mode change
  const handleViewModeChange = React.useCallback((event: React.MouseEvent<HTMLElement>, newValue: 'tree' | 'grid' | null) => {
    if (newValue && (!isSimplifiedView || newValue === 'grid')) {
      setViewMode(newValue);
      setPreviousViewMode(newValue);
    }
  }, [isSimplifiedView]);

  return (
    <Box pt={3}>

      <Box>
        <Box sx={{p: 1, pb: 2}}>
          <Tooltip title={'Go Back Home'} placement="top" arrow>
              <span>
                <Button variant='outlined' color={'primary'} onClick={() => backToHome()} disabled={isSomethingInProgress()} startIcon={<ArrowBack/>}>Go Back Home</Button>
              </span>
          </Tooltip>
        </Box>
      </Box>

      
      {isSomethingInProgress() && 
        <Box py={2}>
          <LinearProgress sx={{
            height: '4px',
            borderRadius: '2px'
          }} />
        </Box>
      }

      <Box sx={{ display: 'flex', justifyContent: 'center', pb: 1 }}>

        <Box sx={{p: 1, width: theme.spacing(26)}}>

          <ResumeCard
            resumeB62Id={params.resumeB62Id!}
            fileName={fileName}
            resumeUpdatedAt={fileUpdatedAt}
            onRemoveResume={(resumeB62Id: string) => removeResume(resumeB62Id)}
            removeResumeInProgress={removeResumeInProgress}
            isDisabled={isSomethingInProgress()}
            displayNavigateToResources={false}
            isResumeStatusFailed={false}
          ></ResumeCard>

        </Box>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box component={'img'} src={ImgLogo}
          sx={{borderRadius: '50%', width: theme.spacing(9.6), opacity: 1.0,  
            background: lighten(theme.palette.primary.main!, 0.85),
            border: `1px solid ${lighten(theme.palette.primary.main!, 0.6)}`
          }}></Box>
      </Box>

      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, mt: 2 }}>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={isSimplifiedView}
                  onChange={handleSimplifiedViewToggle}
                  name="simplifiedView"
                  disabled={isLoading || isLoadingJobDescriptions || isLoadingTailoreds}
                />
              }
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography>Show only latest versions</Typography>
                  {(isLoading || isLoadingJobDescriptions || isLoadingTailoreds) && (
                    <CircularProgress size={16} sx={{ ml: 1 }} />
                  )}
                </Box>
              }
            />
          </Box>
          <ToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={handleViewModeChange}
            aria-label="view mode"
          >
            <ToggleButton value="grid" aria-label="grid view">
              <Tooltip title="Grid View" placement="top" arrow>
                <GridView />
              </Tooltip>
            </ToggleButton>
            {!isSimplifiedView && (
              <ToggleButton value="tree" aria-label="tree view">
                <Tooltip title="Tree View" placement="top" arrow>
                  <AccountTree />
                </Tooltip>
              </ToggleButton>
            )}
          </ToggleButtonGroup>
        </Box>

        {viewMode === 'grid' ? (
          <Grid container spacing={3} pt={4} pb={3} justifyContent="center">
            {!cards.length && !isSomethingInProgress() && 
              <Typography variant="body2" sx={{ textAlign: 'center', width: '100%' }}>
                Here you can see list of tailored resumes, created previously.
              </Typography>
            }
            {cards.map((card) => (
              <Grid item xs={12} sm={6} md={4} key={card.tailored_resume.base62_id}>
                {card.isDeleting && 
                  <Box py={2}>
                    <LinearProgress 
                      sx={{ 
                        height: '4px',
                        borderRadius: '2px',
                        borderTopRightRadius: '16px', 
                        borderTopLeftRadius: '16px' 
                      }}
                    />
                  </Box>
                }
                <Card
                  sx={{ height: 1, alignContent: 'end', borderRadius: theme.spacing(1.5) }}>
                  <CardContent sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>

                    <Box pb={1}>
                      {card.tailored_resume?.base62_id && (
                        <>
                        <Box sx={{ 
                          background: card.tailored_resume.previous_base62_id
                            ? `linear-gradient(135deg, ${alpha(theme.palette.secondary.main, 0.9)}, ${alpha(theme.palette.info.main, 0.9)})`
                            : alpha(theme.palette.secondary.main, 0.9),
                          backdropFilter: 'blur(8px)',
                          border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
                          padding: theme.spacing(2),
                          borderRadius: theme.spacing(1.5),
                          boxShadow: `0 8px 32px ${alpha(theme.palette.common.black, 0.15)}`,
                          '&:hover': {
                            background: card.tailored_resume.previous_base62_id
                              ? `linear-gradient(135deg, ${alpha(theme.palette.secondary.main, 0.95)}, ${alpha(theme.palette.info.main, 0.95)})`
                              : alpha(theme.palette.secondary.main, 0.95),
                            boxShadow: `0 8px 32px ${alpha(theme.palette.common.black, 0.2)}`,
                            transition: 'all 0.3s ease-in-out'
                          }
                        }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }} pb={1}>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', overflow: 'hidden' }}>
                              <Tooltip title={card.job_description?.title} placement="top" arrow>
                                <Typography variant="subtitle1" sx={{ 
                                  color: 'white',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  maxWidth: '70%'
                                }}>{card.job_description?.title}</Typography>
                              </Tooltip>
                              <Typography sx={{ mx: 1, color: 'white', flexShrink: 0 }}>&#x2022;</Typography>
                              <Tooltip title={card.job_description?.company || '-'} placement="top" arrow>
                                <Typography sx={{ 
                                  color: 'white',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  maxWidth: '30%'
                                }}>{card.job_description?.company || '-'}</Typography>
                              </Tooltip>
                            </Box>
                          </Box>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                              Tailored at <Box component="span" sx={{ fontWeight: 'bold', color: 'white' }}>{formatUpdatedAt(card.tailored_resume.updated_at)}</Box>
                            </Typography>
                            <Typography variant="caption" sx={{ 
                              color: 'rgba(255, 255, 255, 0.5)', 
                              fontSize: '0.7rem',
                              alignSelf: 'flex-end'
                            }}>
                              {card.tailored_resume.base62_id}
                            </Typography>
                          </Box>
                        </Box>
                        </>
                      )}
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, pt: 2 }}>
                      <Box sx={{ 
                        flexGrow: 1,
                        pl: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        maxWidth: 'calc(100% - 48px)'
                      }}>
                        <ResumeThumbnail 
                          thumbnailUrl={thumbnails?.[card?.tailored_resume?.base62_id]}
                          onClick={() => card.tailored_resume.latex_resume_base62_id ? navigateToPdfResume(card) : navigateToTailoredResume(card)}
                        />
                      </Box>

                      <Box pb={1} pt={1} sx={{ 
                        alignSelf: 'end',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        ml: 2,
                        width: '48px'
                      }}>
                        {card.tailored_resume?.base62_id && (
                          <Tooltip title={card.tailored_resume.latex_resume_base62_id ? "View tailored resume" : "Please vizit edit page to generate the PDF"} placement="top" arrow>
                            <span>
                              <IconButton 
                                color="primary" 
                                onClick={() => card.tailored_resume.latex_resume_base62_id ? navigateToPdfResume(card) : null}
                                disabled={!card.tailored_resume.latex_resume_base62_id}
                              >
                                <Visibility />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                        
                        {(!card.tailored_resume?.base62_id) ? (
                          <Typography variant="body2" color="error">No Tailored Resume</Typography>
                        ) : (
                          <Tooltip title="Edit tailored resume" placement="top" arrow>
                            <span>
                              <IconButton color="primary" onClick={() => navigateToTailoredResume(card)}>
                                <Edit />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                        
                        {card.tailored_resume?.base62_id && (
                          <Tooltip title="Delete tailored resume" placement="top" arrow>
                            <span>
                              <IconButton 
                                color="error" 
                                onClick={() => setCardToRemove(card)} 
                                disabled={card.isDeleting}
                              >
                                <DeleteForever />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                      </Box>
                    </Box>

                    { card.job_description?.body && <Box pt={2}>
                      <Typography sx={{
                        padding: theme.spacing(2),
                        paddingLeft: theme.spacing(3),
                        borderLeft: `4px solid ${alpha(theme.palette.primary.main, 0.5)}`,
                        borderRadius: 0,
                        fontSize: '12px',
                        backgroundColor: alpha(theme.palette.primary.main, 0.03),
                        fontStyle: 'italic'
                      }}>{card.job_description?.body || ''}</Typography>
                    </Box> }

                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box sx={{ 
            maxWidth: '100%',
            margin: '0 auto',
            overflowX: 'auto',
            padding: theme.spacing(4),
            position: 'relative',
            minHeight: '100%'
          }}>
            {!cards.length && !isSomethingInProgress() && 
              <Typography variant="body2" sx={{ textAlign: 'center', width: '100%' }}>
                Here you can see list of tailored resumes, created previously.
              </Typography>
            }
            {cards.length > 0 && (
              <>
                <Box sx={{ 
                  pl: theme.spacing(12),
                  ml: theme.spacing(6),
                  borderLeft: `2px solid ${alpha(theme.palette.primary.main, 0.3)}`,
                  position: 'relative',
                  pt: theme.spacing(4),
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    left: '-6px',
                    top: '-6px',
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    backgroundColor: theme.palette.primary.main,
                    zIndex: 0
                  }
                }}>
                  {buildTailoredResumeTree(cards).map((node, index) => (
                    <Box key={node.card.tailored_resume.base62_id} sx={{ 
                      position: 'relative',
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        left: theme.spacing(-12),
                        top: '310px',
                        width: theme.spacing(12),
                        height: '2px',
                        backgroundColor: alpha(theme.palette.primary.main, 0.3),
                        transform: 'translateY(-50%)',
                        zIndex: 0
                      }
                    }}>
                      {renderTreeNode(node)}
                    </Box>
                  ))}
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>

      {errorMessage &&
        <Box pb={2} pt={1}>
          <Alert severity="error"><div dangerouslySetInnerHTML={{ __html: errorMessage }} /></Alert>
        </Box>
      }

      <FeedbackSidePopup></FeedbackSidePopup>

      <ConfirmDialogBox
        title="Please confirm"
        message="Please confirm you want to delete this tailored resume!"
        open={!!cardToRemove}
        primaryButton={{ label: 'Delete', onClick: () => {deleteTailoredResume(cardToRemove!);setCardToRemove(undefined);} }}
        secondaryButton={{ label: 'Cancel', onClick: () => setCardToRemove(undefined) }}
        fullWidth
        onClose={() => setCardToRemove(undefined)}
      />

    </Box>
  );
}
