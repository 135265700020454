import React, { useState } from 'react';
import { Box, IconButton, Typography, Chip } from '@mui/material';
import { Control, useFieldArray, useFormContext } from 'react-hook-form';
import PrimaryTextInput from '../PrimaryTextInput';
import {
  DeleteForever,
  Add as IconAdd,
  Close as CloseIcon,
} from '@mui/icons-material';
import { getId } from './utils';
import TextDiffComponent from './TextDiffComponent';
import { FormValues } from './ResumeForm';
import { Controller } from 'react-hook-form';
import { borderedSectionStyles } from './styles';
import theme from '../../theme';

interface CertificationField {
  id?: string;
  name: string;
  issuer?: string;
  date_issued?: string;
  expiry_date?: string;
  credential_id?: string;
}

interface Props {
  control: Control<FormValues>;
  _required?: boolean;
  diffOriginData?: any;
  showDiff?: boolean;
}

const ResumeFormCertifications: React.FC<Props> = ({ control, _required, diffOriginData, showDiff }) => {
  const { setValue } = useFormContext<FormValues>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'certifications',
  });

  const [deletingCertificationIndex, setDeletingCertificationIndex] = useState<number | null>(null);

  const handleAddCertification = () => {
    append({
      name: '',
      issuer: '',
      date_issued: '',
      expiry_date: '',
      credential_id: '',
    });
  };

  return (
    <Box>
      <Typography>Certifications
        <IconButton onClick={handleAddCertification}
          disabled={fields.length > 0 && !fields[fields.length - 1].name}>
          <IconAdd/>
        </IconButton>
      </Typography>

      {fields.map((field, index) => (
        <Box key={field.id} sx={{display: 'flex', position: 'relative', pb: 1}}>
          <Box sx={{position: 'absolute', left: theme.spacing(-1), top: theme.spacing(-1)}}>
            <Chip label={index + 1} size="small" sx={{color: theme.palette.text.secondary}} />
          </Box>

          <Box sx={{
            flexGrow: 1, 
            ...borderedSectionStyles,
            ...(deletingCertificationIndex === index && {
              borderColor: `${theme.palette.error.main} !important`,
              '& .MuiInputBase-input': {
                color: `${theme.palette.error.main} !important`,
                textDecoration: 'line-through',
              }
            })
          }}>
            <Controller
              name={`certifications.${index}.name`}
              control={control}
              defaultValue={field.name || ''}
              render={({ field: { onChange, value } }) => (
                <>
                  <PrimaryTextInput
                    id={getId(`certifications.${index}.name`)}
                    name={`certifications.${index}.name`}
                    label="Certification Name"
                    onChange={onChange}
                    value={value}
                    required={_required}
                    placeholder="e.g., AWS Certified Solutions Architect"
                    multiline={true}
                  />
                  {showDiff && (
                    <TextDiffComponent
                      originalText={diffOriginData?.certifications?.[index]?.name || ''}
                      updatedText={value || ''}
                      onTextUpdated={onChange}
                    />
                  )}
                </>
              )}
            />

            <Controller
              name={`certifications.${index}.issuer`}
              control={control}
              defaultValue={field.issuer || ''}
              render={({ field: { onChange, value } }) => (
                <>
                  <PrimaryTextInput
                    id={getId(`certifications.${index}.issuer`)}
                    name={`certifications.${index}.issuer`}
                    label="Issuing Organization"
                    onChange={onChange}
                    value={value}
                    placeholder="e.g., Amazon Web Services"
                    multiline={true}
                  />
                  {showDiff && (
                    <TextDiffComponent
                      originalText={diffOriginData?.certifications?.[index]?.issuer || ''}
                      updatedText={value || ''}
                      onTextUpdated={onChange}
                    />
                  )}
                </>
              )}
            />

            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={{ flex: 1 }}>
                <Controller
                  name={`certifications.${index}.date_issued`}
                  control={control}
                  defaultValue={field.date_issued || ''}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <PrimaryTextInput
                        id={getId(`certifications.${index}.date_issued`)}
                        name={`certifications.${index}.date_issued`}
                        label="Date Issued"
                        onChange={onChange}
                        value={value}
                        placeholder="e.g., June 2023"
                        multiline={true}
                      />
                      {showDiff && (
                        <TextDiffComponent
                          originalText={diffOriginData?.certifications?.[index]?.date_issued || ''}
                          updatedText={value || ''}
                          onTextUpdated={onChange}
                        />
                      )}
                    </>
                  )}
                />
              </Box>

              <Box sx={{ flex: 1 }}>
                <Controller
                  name={`certifications.${index}.expiry_date`}
                  control={control}
                  defaultValue={field.expiry_date || ''}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <PrimaryTextInput
                        id={getId(`certifications.${index}.expiry_date`)}
                        name={`certifications.${index}.expiry_date`}
                        label="Expiry Date (if applicable)"
                        onChange={onChange}
                        value={value}
                        placeholder="e.g., June 2026"
                        multiline={true}
                      />
                      {showDiff && (
                        <TextDiffComponent
                          originalText={diffOriginData?.certifications?.[index]?.expiry_date || ''}
                          updatedText={value || ''}
                          onTextUpdated={onChange}
                        />
                      )}
                    </>
                  )}
                />
              </Box>

              <Box sx={{ flex: 1 }}>
                <Controller
                  name={`certifications.${index}.credential_id`}
                  control={control}
                  defaultValue={field.credential_id || ''}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <PrimaryTextInput
                        id={getId(`certifications.${index}.credential_id`)}
                        name={`certifications.${index}.credential_id`}
                        label="Credential ID"
                        onChange={onChange}
                        value={value}
                        placeholder="e.g., AWS-ASA-123456"
                        multiline={true}
                      />
                      {showDiff && (
                        <TextDiffComponent
                          originalText={diffOriginData?.certifications?.[index]?.credential_id || ''}
                          updatedText={value || ''}
                          onTextUpdated={onChange}
                        />
                      )}
                    </>
                  )}
                />
              </Box>
            </Box>
          </Box>

          <Box pt={'13px'}>
            {deletingCertificationIndex === index ? (
              <Box sx={{ display: 'flex', gap: 0.5 }}>
                <IconButton 
                  size="small"
                  onClick={() => {
                    remove(index);
                    setDeletingCertificationIndex(null);
                  }}
                  sx={{ color: theme.palette.error.main }}
                >
                  <DeleteForever/>
                </IconButton>
                <IconButton 
                  size="small"
                  onClick={() => setDeletingCertificationIndex(null)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : (
              <IconButton 
                onClick={() => setDeletingCertificationIndex(index)}
              >
                <DeleteForever/>
              </IconButton>
            )}
          </Box>
        </Box>
      ))}

      {fields.length > 0 && 
        <Typography variant="body1" sx={{fontSize: '12px'}}>Add More Certifications
          <IconButton onClick={handleAddCertification}
            disabled={fields.length > 0 && !fields[fields.length - 1].name}>
            <IconAdd/>
          </IconButton>
        </Typography>
      }
    </Box>
  );
};

export default ResumeFormCertifications; 