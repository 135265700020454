import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography, Theme,TextField, styled, Chip, Checkbox } from "@mui/material";
import {
  DeleteForever,
  Add as IconAdd,
  Undo,
  Close as IconClose,
} from '@mui/icons-material';
import PrimaryTextInput from "../PrimaryTextInput";
import { Controller, useFieldArray} from "react-hook-form";
import TextDiffComponent from "./TextDiffComponent";
import DateOrDates from "./DateOrDates";
import { borderedSectionStyles, formDiffRedColor } from "./styles";
import theme from "../../theme";
import { FormRemovedDetail } from "./FormRemovedDetail";
import { getId } from "./utils";
import { stat } from "fs";

const PREFIX = 'ResumeForm';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`
};


const Root = styled(Box)(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1),
  
    display: "flex",
    flexDirection: 'column',
    alignItems: "left",
    justifyContent: "left",
  },

}));


// This are copied from education section
// "references": {
//   "default": [],
//   "description": "Candidate references titles",
//   "items": {
//       "$ref": "#/$defs/ReferencesEvent"
//   },
//   "title": "References",
//   "type": "array"
// },

// This are copied from education section
// "ReferencesEvent": {
//   "properties": {
//       "institution": {
//           "title": "Institution",
//           "type": "string"
//       },
//       "title": {
//           "title": "Title",
//           "type": "string"
//       },
//       "details": {
//           "items": {
//               "type": "string"
//           },
//           "title": "Details",
//           "type": "array"
//       },
//       "dates": {
//           "anyOf": [
//               {
//                   "type": "string"
//               },
//               {
//                   "type": "null"
//               }
//           ],
//           "default": null,
//           "title": "Dates"
//       }
//   },
//   "required": [
//       "institution",
//       "title",
//       "details"
//   ],
//   "title": "ReferencesEvent",
//   "type": "object"
// },


// {
//   "dates": "Aug 2012 – Dec 2013",
//   "details": [
//     "GPA: 4.00"
//   ],
//   "title": "Master of Science in Computer Science",
//   "institution": "Georgia Institute of Technology"
// }


interface ResumeFormReferencesProps {
  control: any,
  _required?: boolean,
  diffOriginData?: any,
  showDiff: boolean
}

// {

//   "name": "string",
//   "title": "string",
//   "company": "string",
//   "email": "string",
//   "phone": "string"

// }


const ResumeFormReferences: React.FC<ResumeFormReferencesProps> = ({ control, _required, diffOriginData, showDiff }) => {
  // eslint-disable-next-line
  const { fields, append, prepend, remove, swap, move, insert, replace } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: 'references.references', // unique name for your Field Array
  });

  const references = control._formValues?.references?.references;

  const [state, setState] = useState<number>(0); // solely to trigger re-render for to have updated awards variable in the component

  // fix: nested 'references.references' doesn't render correctly by itself, using replace() method to trigger correct rendering.
  const [stateToPreventInfiniteRender, setStateToPreventInfiniteRender] = useState<number>(0);
  useEffect(() => {
    if (references && references?.length && !stateToPreventInfiniteRender) {
      replace(references)
      setStateToPreventInfiniteRender(1)
    }
  }, [references])

  // Add state for delete confirmation
  const [deleteConfirmationIndex, setDeleteConfirmationIndex] = useState<number | null>(null);

  return <Root className={classes.root}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography>References</Typography>
      <IconButton onClick={() => {append({})}}
        disabled={references?.length > 0 && !(references[references.length -1]?.name || references[references.length -1]?.title || references[references.length -1]?.email)}>
        <IconAdd/>
      </IconButton>

      <Controller
        name={`references.more_available_on_request`}
        control={control}
        render={({ field: { onChange, onBlur, value, name } }) => (
          <Box sx={{display: 'inline'}} pl={4}>
            <Typography sx={{display: 'inline', fontStyle: 'italic', fontSize: '14px'}}>More available on request</Typography>
            <Checkbox
              id={getId(name)}
              name="more_available_on_request"
              onChange={(event:any) => {onChange(event); setState(state + 1)}}
              required={_required && false}
              checked={ value || false}
              defaultValue={ diffOriginData?.references?.more_available_on_request || value || false}
            />
          </Box>
        )}
      />
    </Box>

    {fields.map((field: any, index: number) => (
      <Box key={field.id} sx={{display: 'flex', position: 'relative', pb: 1}}>

        <Box sx={{position: 'absolute', left: theme.spacing(-1), top: theme.spacing(-1)}}>
          <Chip label={index + 1} size="small" sx={{color: theme.palette.text.secondary}} />
        </Box>

        <Box sx={{flexGrow: 1, ...borderedSectionStyles}}>

          <Controller
            name={`references.references.${index}.name`}
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <Box>
                <PrimaryTextInput
                  id={getId(name)}
                  name="name"
                  label="Name"
                  onChange={(event:any) => {onChange(event); setState(state + 1)}}
                  value={ value || ''}
                  defaultValue={ diffOriginData?.references?.references?.[index]?.name || ''}
                  required={_required && true}
                  multiline={true}
                  sx={deleteConfirmationIndex === index ? {
                    "& .MuiInputBase-input": {
                      textDecoration: "line-through",
                      color: `${theme.palette.error.main} !important`
                    }
                  } : undefined}
                />
              </Box>
            )}
          />

          <Controller
            name={`references.references.${index}.title`}
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <Box>
                <PrimaryTextInput
                  id={getId(name)}
                  name="title"
                  label="Title"
                  onChange={(event:any) => {onChange(event); setState(state + 1)}}
                  value={ value || '' }
                  defaultValue={ diffOriginData?.references?.references?.[index]?.title || '' }
                  required={_required && false}
                  multiline={true}
                  sx={deleteConfirmationIndex === index ? {
                    "& .MuiInputBase-input": {
                      textDecoration: "line-through",
                      color: `${theme.palette.error.main} !important`
                    }
                  } : undefined}
                />
              </Box>
            )}
          />

          <Controller
            name={`references.references.${index}.company`}
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <Box>
                <PrimaryTextInput
                  id={getId(name)}
                  name="company"
                  label="Company"
                  onChange={(event:any) => {onChange(event); setState(state + 1)}}
                  value={ value || ''}
                  defaultValue={ diffOriginData?.references?.references?.[index]?.company || ''}
                  required={_required && false}
                  multiline={true}
                  sx={deleteConfirmationIndex === index ? {
                    "& .MuiInputBase-input": {
                      textDecoration: "line-through",
                      color: `${theme.palette.error.main} !important`
                    }
                  } : undefined}
                />
              </Box>
            )}
          />

          <Controller
            name={`references.references.${index}.email`}
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <Box>
                <PrimaryTextInput
                  id={getId(name)}
                  name="email"
                  label="Email"
                  onChange={(event:any) => {onChange(event); setState(state + 1)}}
                  value={ value || ''}
                  defaultValue={ diffOriginData?.references?.references?.[index]?.email || ''}
                  required={_required && true}
                  multiline={true}
                  sx={deleteConfirmationIndex === index ? {
                    "& .MuiInputBase-input": {
                      textDecoration: "line-through",
                      color: `${theme.palette.error.main} !important`
                    }
                  } : undefined}
                />
              </Box>
            )}
          />

          <Controller
            name={`references.references.${index}.phone`}
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <Box>
                <PrimaryTextInput
                  id={getId(name)}
                  name="phone"
                  label="Phone"
                  onChange={(event:any) => {onChange(event); setState(state + 1)}}
                  value={ value || ''}
                  defaultValue={ diffOriginData?.references?.references?.[index]?.email || ''}
                  required={_required && false}
                  multiline={true}
                  sx={deleteConfirmationIndex === index ? {
                    "& .MuiInputBase-input": {
                      textDecoration: "line-through",
                      color: `${theme.palette.error.main} !important`
                    }
                  } : undefined}
                />
              </Box>
            )}
          />


        </Box>

        <Box pt={'13px'}>
          {deleteConfirmationIndex === index ? (
            <>
              <IconButton 
                onClick={() => {
                  remove(index);
                  setDeleteConfirmationIndex(null);
                }}
                color="error"
              >
                <DeleteForever/>
              </IconButton>
              <IconButton 
                onClick={() => setDeleteConfirmationIndex(null)}
              >
                <IconClose/>
              </IconButton>
            </>
          ) : (
            <IconButton onClick={() => setDeleteConfirmationIndex(index)}>
              <DeleteForever/>
            </IconButton>
          )}
        </Box>
      </Box>
    ))}

    {fields.length > 0 && <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
      <Typography variant="body1" sx={{fontSize: '12px'}}>Add More References</Typography>
      <IconButton onClick={() => {append({})}}
        disabled={references?.length > 0 && !(references[references.length -1]?.name || references[references.length -1]?.title  || references[references.length -1]?.email)}>
        <IconAdd/>
      </IconButton>
    </Box>}

    {/* Removed references */}
    { showDiff && (diffOriginData?.references?.references?.length > fields?.length) &&
      [...(diffOriginData?.references.references || [])].slice(fields?.length).map((field: any, index: number) => (

      <Box key={field.id} sx={{display: 'flex'}}>

        <Box sx={{flexGrow: 1, ...borderedSectionStyles,
          position: 'relative',
          border: `6px dashed ${formDiffRedColor}`
        }}>

          <Box>
            <Typography variant="body2" sx={{background: formDiffRedColor, p:1, borderRadius: theme.spacing(0.5)}}>
              Deleted References{field?.title ? ':' : ''} {field?.title}
            </Typography>
          </Box>

          <Box>
            <PrimaryTextInput
              name="name"
              label="Name"
              value={ field?.name || '' }
              multiline={true}
              disabled={true}
            />
          </Box>

          <Box>
            <PrimaryTextInput
              name="title"
              label="Title"
              value={ field?.title || '' }
              multiline={true}
              disabled={true}
            />
          </Box>

          <Box>
            <PrimaryTextInput
              name="company"
              label="Company"
              value={ field?.company || '' }
              multiline={true}
              disabled={true}
            />
          </Box>

          <Box>
            <PrimaryTextInput
              name="email"
              label="Email"
              value={ field?.email || '' }
              multiline={true}
              disabled={true}
            />
          </Box>

          <Box>
            <PrimaryTextInput
              name="phone"
              label="Phone"
              value={ field?.phone || '' }
              multiline={true}
              disabled={true}
            />
          </Box>

        </Box>

        <Box pt={'13px'}>
          <IconButton onClick={() => {append(field)}}>
            <Undo/>
          </IconButton>
        </Box>

      </Box>
    ))}


  </Root>
}



export default ResumeFormReferences;

