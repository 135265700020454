import React from "react";
import {  Alert, styled } from '@mui/material';
import {Box,Typography, Theme, Button } from "@mui/material";
import { useDropzone } from "react-dropzone";
import PrimaryText from "./PrimaryText";
import Progressbar from "./Progressbar";
import useUser from "../Hooks/useUser";
import { useDispatch } from "react-redux";
import axios from "axios";
import clsx from 'clsx';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { onAlertShowAction } from "../Redux";
import { httpOnSaveFileData, httpPollGetResumeByResumeFileId, httpPollResumeExtracted } from "../Services/Http";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const PREFIX = 'FileUploadModal';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  box: `${PREFIX}-box`,
  dragNdrop: `${PREFIX}-dragNdrop`,
  modalRoot: `${PREFIX}-modalRoot`,
  textInput: `${PREFIX}-textInput`,
  iconContainer: `${PREFIX}-iconContainer`,
  error: `${PREFIX}-error`,
  button: `${PREFIX}-button`,
  browseFilesButton: `${PREFIX}-browseFilesButton`,
  title: `${PREFIX}-title`
};


const Root = styled(Box)(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    border: '2px dashed #9e9e9e',
    padding: theme.spacing(1),
  
    display: "flex",
    flexDirection: 'column',
    alignItems: "center",
    justifyContent: "center",
    borderRadius: '12px',
  },

  [`& .${classes.container}`]: {
    position: "absolute",
    borderRadius: 7,
    width: "50%",
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    margin: theme.spacing(2),
    backgroundColor: theme.colors.white,

  },

  [`& .${classes.box}`]: {
    alignItems: "center",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.dragNdrop}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(3),
    borderRadius: 7,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: theme.palette.secondary.main,

  },

  [`& .${classes.modalRoot}`]: {
    width: "100%",
  },

  [`& .${classes.textInput}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    textAlign: "center",
    marginTop: theme.spacing(3),
  },

  [`& .${classes.iconContainer}`]: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },

  [`& .${classes.error}`]: {
    color: theme.colors.error,
    width: '100%',
    justifyContent: 'flex-start',
    display: 'flex',
  },

  [`& .${classes.button}`]: {
    marginTop: theme.spacing(3)
  },

  [`& .${classes.browseFilesButton}`]: {
    marginTop: theme.spacing(2)
  },

  [`& .${classes.title}`]: {
    fontSize: theme.fontSize.lg,
    fontWeight: 'bold',
    color: theme.palette.primary.main
  }
}));

interface Props {
  onSetFileName: (fileName: string) => void
  onUploadCompleted: (fileB62Id: string, fileCreatedAt: string) => void,
  onUploadCompletedWithResumeValidationError: (fileB62Id: string, fileCreatedAt: string) => void,
}


const FileUpload: React.FC<Props> = ({ onSetFileName, onUploadCompleted, onUploadCompletedWithResumeValidationError }) => {

  const [error, setError] = React.useState<string>('');
  const [fileName, setFileName] = React.useState<string>("");
  const [fileExtension, setFileExtension] = React.useState<string>("");
  const [uploading, setUploading] = React.useState<boolean>(false);
  // eslint-disable-next-line
  const [isDisabled, setIsDisabled] = React.useState<boolean>(true);
  const { userId } = useUser();
  const [progressPercentage, setProgressPercentage] = React.useState(0);
  const [showRetryButton, setShowRetryButton] = React.useState<boolean>(false);
  const [lastUploadedFileId, setLastUploadedFileId] = React.useState<string>("");

  const [progressMessage, setProgressMessage] = React.useState<string>("");
  const [progressMessageError, setProgressMessageError] = React.useState<string>("");

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'text/*': ['.pdf']
    },
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        setError("Invalid file type!");
      } else if (acceptedFiles.length > 0) {
        setError("");
      }
    },
  });

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (acceptedFiles.length > 0) {
      onSetFileData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  } , [acceptedFiles, /* onSetFileData */]);

  const onSetFileData = (): void => {
    if (acceptedFiles.length > 0) {
      const fullFileName = acceptedFiles[0].name;
      const fileNameArray = fullFileName.split(".");
      const newFileName = fileNameArray[0];
      let newFileExtension = fileNameArray[fileNameArray.length - 1];
      newFileExtension = "." + newFileExtension;
      setFileName(newFileName);
      setFileExtension(newFileExtension);

      setIsDisabled(false)

      onSetFileName(newFileName);

      onUploadFile(newFileName, newFileExtension)

    } else {
      setFileName("");
    }
  };

  const onUploadFile = (fileName: string, fileExtension: string): void => {
    setProgressMessage('');
    if (fileExtension === "") {
      setError("Please select a file");
    }
    else if (fileName === "") {
      setError("Required!");
    }
    else {
      setError("");
      setProgressMessage(`Uploading ${fileName}${fileExtension}`);

      const file = acceptedFiles[0];
      const contentType = file.type;
      const fullExtension = fileExtension.split('.');
      const fileType: any = fullExtension.pop();
      // const uploadingFileName = fileName + fileExtension;
      setUploading(true);
      setIsDisabled(true);
      /*Upload the file data to DynamoDB and get the Signed URL*/
      httpOnSaveFileData(fileName, fileType, contentType, userId)
        .then((res: any) => {
          const resumeFileB62Id = res.data.base62_id;
          const presignedUrl = res.data.url;
          const options = {
            headers: {
              'Content-Type': file.type
            },
            onUploadProgress: (progressEvent: any) => {
              if (progressEvent.total !== undefined) {
                const percentCompleted = Math.round((progressEvent.loaded * 60) / progressEvent.total);
                setProgressPercentage(percentCompleted)
              }
              else {
                setProgressPercentage(0);
              }
            }
          }
          axios.put(presignedUrl, file, options)
            .then(response => {

              setProgressPercentage(62);

              setProgressMessage(`Processing uploaded file ${fileName}${fileExtension}`);

              setTimeout(() => { // give a second for s3 triggered lambda to create an resume record

                setProgressPercentage(65);

                httpPollGetResumeByResumeFileId(resumeFileB62Id, userId)
                .then((res: any) => {
                  setProgressPercentage(70);

                  const resumeB62Id = res.data.base62_id;
                  const updatedAt = res.data.updated_at;

                  httpPollResumeExtracted(resumeB62Id, userId, 3000, () => {

                    setProgressPercentage((p) => {
                      if (p < 94) {
                        return p + 2;
                      }
                      return p;
                    })
                  })
                  .then((res: any) => {

                    console.log('httpPollResumeExtracted', res)

                    if (res.data.status === 'Ready') {
                      setProgressPercentage(100);
                      setProgressMessage(`Resume successfully processed ${fileName}${fileExtension}`);

                      setTimeout(() => {
                        onUploadCompleted(resumeB62Id, updatedAt);
                        onReset();
                        dispatch(onAlertShowAction({ type: 'success', message: 'Resume file uploaded and processed successfully!' }));
                      }, 500);
                    }

                  })
                  .catch((error: any) => {

                    if (error?.data?.status === 'Failed') {

                      if (error?.data?.status_message ===	"The input is not a resume.") {
                        setProgressMessage('')
                        setError(`Our system wasn't able to recognize this file as a resume. Please provide another file or feel free to contact our support.`);
                      } else if (error?.data?.status_message_details) {
                        setProgressPercentage(90);
                        setProgressMessage('')
                        setError(`Resume processing failed redirecting edit resume page.`);

                        setTimeout(() => {
                          onUploadCompletedWithResumeValidationError(resumeB62Id, updatedAt);
                          onReset();
                          dispatch(onAlertShowAction({ type: 'success', message: 'Resume processing failed redirecting edit resume page.' }));
                        }, 500);
                      } else {
                        setProgressPercentage(90);
                        setProgressMessage('')
                        const errorMessage = `Resume processing failed.`;
                        setError(errorMessage);
                        dispatch(onAlertShowAction({ type: 'success', message: errorMessage }));
                      }
                      
                    }
                    setUploading(false);
                    setShowRetryButton(true);
                    setLastUploadedFileId(resumeB62Id);
                    dispatch(onAlertShowAction({ type: 'error', message: 'File upload processing failed. You can retry or try again later.' }));
                    
                  });
  
                })
                .catch((error: any) => {
                  setUploading(false);
                  setShowRetryButton(true);
                  dispatch(onAlertShowAction({ type: 'error', message: 'File processing retry failed!' }));
                });

              }, 1500);

            })
            .catch(error => {
              setUploading(false);
              dispatch(onAlertShowAction({ type: 'error', message: 'File processing failed!' }));
            });
        })
        .catch(() => {
          setUploading(false);
          dispatch(onAlertShowAction({ type: 'error', message: 'File uploaded failed!' }));
        });
    }
  };

  const handleRetry = () => {
    setShowRetryButton(false);
    setError("");
    setProgressMessage(`Retrying process for ${fileName}${fileExtension}`);
    setProgressPercentage(65);
    setUploading(true);
    
    httpPollGetResumeByResumeFileId(lastUploadedFileId, userId)
      .then((res: any) => {
        setProgressPercentage(70);
        
        const resumeB62Id = res.data.base62_id;
        const updatedAt = res.data.updated_at;
        
        httpPollResumeExtracted(resumeB62Id, userId, 3000, () => {
          setProgressPercentage((p) => {
            if (p < 94) {
              return p + 2;
            }
            return p;
          })
        })
        .then((res: any) => {
          console.log('httpPollResumeExtracted', res)
          
          if (res.data.status === 'Ready') {
            setProgressPercentage(100);
            setProgressMessage(`Resume successfully processed ${fileName}${fileExtension}`);
            
            setTimeout(() => {
              onUploadCompleted(resumeB62Id, updatedAt);
              onReset();
              dispatch(onAlertShowAction({ type: 'success', message: 'Resume file uploaded and processed successfully!' }));
            }, 500);
          }
        })
        .catch((error: any) => {
          // Same error handling as original
          if (error?.data?.status === 'Failed') {
            if (error?.data?.status_message === "The input is not a resume.") {
              setProgressMessage('')
              setError(`Our system wasn't able to recognize this file as a resume. Please provide another file or feel free to contact our support.`);
            } else if (error?.data?.status_message_details) {
              setProgressPercentage(90);
              setProgressMessage('')
              setError(`Resume processing failed redirecting edit resume page.`);
              
              setTimeout(() => {
                onUploadCompletedWithResumeValidationError(resumeB62Id, updatedAt);
                onReset();
                dispatch(onAlertShowAction({ type: 'success', message: 'Resume processing failed redirecting edit resume page.' }));
              }, 500);
            } else {
              setProgressPercentage(90);
              setProgressMessage('')
              const errorMessage = `Resume processing failed.`;
              setError(errorMessage);
              dispatch(onAlertShowAction({ type: 'success', message: errorMessage }));
            }
          }
          setUploading(false);
          setShowRetryButton(true);
          dispatch(onAlertShowAction({ type: 'error', message: 'Resume processing failed!' }));
        });
      })
      .catch((error: any) => {
        setUploading(false);
        setShowRetryButton(true);
        dispatch(onAlertShowAction({ type: 'error', message: 'File processing retry failed!' }));
      });
  };

  const onReset = (): void => {
    setFileName("");
    setFileExtension("");
    setError("");
    setIsDisabled(true);
    setProgressMessage("");
    setProgressPercentage(0);
    setShowRetryButton(false);
    setLastUploadedFileId("");
  };


  return (
      <Root
        {...getRootProps({ className: "dropzone" })}
        className={clsx(classes.root, classes.dragNdrop)}>

        <Box pb={2} pt={1}>
          <Typography p={1} className={classes.title} style={{ color: '#163b5f' }}>
            {'Upload Resume'}
          </Typography>
        </Box>

        <Box>
          <input {...getInputProps()} disabled={uploading} />
          <Typography variant="body1">{`Drag 'n' drop your PDF resume`}</Typography>
        </Box>
        <Box pt={3}>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            disabled={uploading}
          >
            Upload resume file
            <VisuallyHiddenInput type="file" />
          </Button>
        </Box>

        <Box className={classes.box} maxWidth="xs">
          
          <div className={classes.textInput}>
            <Typography variant="body1">{progressMessage}</Typography>
          </div>
          {error !== "" && (
            <Alert severity="error">
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <PrimaryText text={error || ''} className={classes.error} fontSize={'sm'} />
                {showRetryButton && (
                  <Button 
                    size="small" 
                    variant="outlined" 
                    color="primary" 
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent dropzone from activating
                      handleRetry();
                    }}
                    sx={{ mt: 1 }}
                  >
                    Retry
                  </Button>
                )}
              </Box>
            </Alert>
          )}
          <Progressbar isLoading={uploading} value={progressPercentage} withValue={true}/>

        </Box>
      </Root>
  );
};

export default FileUpload;
