import {
    CLEAR_PERSIST_ROOT
} from './Types';


export const onCleanPersistRootAction = (): any => {
    return (dispatch: any) => {
        dispatch({ type: CLEAR_PERSIST_ROOT });
    }
}

