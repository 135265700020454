import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  TextField,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  Paper,
} from '@mui/material';
import { ImprovementQuestion, FollowUpOperation, ResumeDetailsApiResponse, Status } from '../Services/Http/interface';
import useUser from '../Hooks/useUser';
import {
  httpInitiateImprovements,
  httpPollImprovements,
  httpSubmitImprovementAnswers,
  httpInitiateFollowUpOperations,
  httpPollFollowUpOperations,
  httpSubmitFollowUpOperations,
  httpApplyFollowUpOperations,
  httpPollTailoredExtracted,
  httpOnGetTailored,
} from '../Services/Http';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

interface Props {
  open: boolean;
  onClose: () => void;
  base62Id: string;
}

const POLL_INTERVAL = 2000; // 2 seconds

const ImprovementDialog: React.FC<Props> = ({ open, onClose, base62Id }) => {
  const { userId } = useUser();
  const navigate = useNavigate();
  const params = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [questions, setQuestions] = useState<ImprovementQuestion[]>([]);
  const [operations, setOperations] = useState<FollowUpOperation[]>([]);
  const [error, setError] = useState<string | null>(null);

  const steps = ['Answer Questions', 'Provide Details', 'Review Improvements'];

  useEffect(() => {
    if (open && activeStep === 0) {
      if (!base62Id) {
        setError('Unable to start improvement process. Please try again later.');
        return;
      }
      checkCurrentState();
    }
  }, [open, base62Id]);

  const checkCurrentState = async () => {
    try {
      setLoading(true);
      setLoadingMessage('Checking current improvement status...');
      setError(null);
      
      // Check current improvement status
      try {
        const response = await httpPollImprovements(base62Id, userId);
        const status = response.data.data.improvement_status;
        
        // Handle different states
        if (status === 'Ready' || status === 'Edited') {
          setLoadingMessage('Loading improvement questions...');
          // If already Ready or Edited, load the questions
          const questionsData = response.data.data.improvement_answers.questions;
          const questionsWithDefaults = questionsData.map((question: ImprovementQuestion) => ({
            ...question,
            user_selections: question.user_selections || 
              (question.answer_type === 'yes_no' ? ['No'] : [])
          }));
          setQuestions(questionsWithDefaults);
          setLoading(false);
        } else if (status === 'Applied') {
          setLoadingMessage('Starting a new improvement process...');
          // If Applied, we need to start a new improvement process
          initiateImprovementProcess();
        } else if (status === 'Queued') {
          setLoadingMessage('Improvement generation is queued. Waiting for processing to begin...');
          // If Queued, poll until ready
          pollUntilReady();
        } else if (status === 'Processing') {
          setLoadingMessage('Improvement generation is in progress...');
          // If Processing, poll until ready
          pollUntilReady();
        } else if (status === 'Failed') {
          setLoadingMessage('Previous attempt failed. Restarting improvement process...');
          // If Failed, try to restart the process
          initiateImprovementProcess();
        } else {
          setLoadingMessage('Starting improvement process...');
          // If New or any other state, start the process
          initiateImprovementProcess();
        }
      } catch (error) {
        setLoadingMessage('Starting improvement process...');
        // If error (likely 404), start the process
        initiateImprovementProcess();
      }
    } catch (err) {
      console.error('Error checking current state:', err);
      setError('Unable to check improvement status. Please try again later.');
      setLoading(false);
    }
  };

  const pollUntilReady = async () => {
    let attempts = 0;
    const MAX_ATTEMPTS = 30; // 60 seconds total with 2-second intervals
    
    while (attempts < MAX_ATTEMPTS) {
      try {
        const response = await httpPollImprovements(base62Id, userId);
        const status = response.data.data.improvement_status;
        
        if (status === 'Ready' || status === 'Edited') {
          setLoadingMessage('Loading improvement questions...');
          const questionsData = response.data.data.improvement_answers.questions;
          const questionsWithDefaults = questionsData.map((question: ImprovementQuestion) => ({
            ...question,
            user_selections: question.user_selections || 
              (question.answer_type === 'yes_no' ? ['No'] : [])
          }));
          setQuestions(questionsWithDefaults);
          setLoading(false);
          return;
        } else if (status === 'Failed') {
          throw new Error('Improvement generation failed');
        } else if (status === 'Queued') {
          setLoadingMessage('Improvement generation is queued. Waiting for processing to begin...');
        } else if (status === 'Processing') {
          setLoadingMessage('Improvement generation is in progress...');
        }
        // Continue polling for Queued or Processing states
      } catch (pollError) {
        console.error('Polling error:', pollError);
        // Continue polling even if there's an error
      }
      
      attempts++;
      await new Promise(resolve => setTimeout(resolve, POLL_INTERVAL));
    }
    
    throw new Error('Timed out waiting for improvement questions');
  };

  const initiateImprovementProcess = async () => {
    try {
      setLoading(true);
      setLoadingMessage('Starting improvement process...');
      setError(null);
      
      // Start improvement process
      await httpInitiateImprovements(base62Id, userId);
      
      // Poll for questions
      await pollUntilReady();
    } catch (err) {
      console.error('Improvement process error:', err);
      if (err instanceof Error && err.message === 'Timed out waiting for improvement questions') {
        setError('The improvement process is taking longer than expected. You can try again or come back later.');
      } else if (err instanceof Error && err.message === 'Improvement generation failed') {
        setError('We encountered an issue generating improvements. Please try again.');
      } else {
        setError('Unable to load improvement questions. Please try again later.');
      }
      setLoading(false);
    }
  };

  const handleQuestionAnswer = (questionIndex: number, answer: string | string[]) => {
    setQuestions(prevQuestions => {
      const newQuestions = [...prevQuestions];
      const question = newQuestions[questionIndex];
      
      // For yes/no questions, always ensure a selection
      if (question.answer_type === 'yes_no') {
        newQuestions[questionIndex] = {
          ...question,
          user_selections: Array.isArray(answer) ? answer : [answer]
        };
      } else {
        // For multi-select, allow empty selections
        newQuestions[questionIndex] = {
          ...question,
          user_selections: Array.isArray(answer) ? answer : [answer]
        };
      }
      
      return newQuestions;
    });
  };

  const handleFollowUpAnswer = (questionIndex: number, optionIndex: number, answer: string) => {
    setQuestions(prevQuestions => {
      const newQuestions = [...prevQuestions];
      const question = { ...newQuestions[questionIndex] };
      const option = { ...question.answer_options[optionIndex] };
      if (option.follow_up_request) {
        option.follow_up_request.user_answer = answer;
        console.log(`Updated follow-up answer for question ${questionIndex}, option ${optionIndex}:`, answer);
      }
      question.answer_options[optionIndex] = option;
      newQuestions[questionIndex] = question;
      return newQuestions;
    });
  };

  const handleOperationApproval = (index: number, approved: boolean) => {
    setOperations(prevOperations => {
      const newOperations = [...prevOperations];
      newOperations[index] = { ...newOperations[index], approved };
      return newOperations;
    });
  };

  const hasUnansweredFollowUps = () => {
    // We're no longer enforcing that all follow-ups must be answered
    return false;
  };

  const handlePrevious = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const hasUnansweredQuestions = () => {
    // We're no longer enforcing that all questions must be answered
    return false;
  };

  const hasChangedFromDefaults = () => {
    return questions.some(question => {
      // For yes/no questions, check if the answer is different from the default "No"
      if (question.answer_type === 'yes_no') {
        return question.user_selections?.[0] !== 'No';
      }
      // For other question types, check if any selections have been made
      return question.user_selections && question.user_selections.length > 0;
    });
  };

  const pollFollowUpOperationsUntilReady = async () => {
    let attempts = 0;
    const MAX_ATTEMPTS = 30; // 60 seconds total with 2-second intervals
    
    while (attempts < MAX_ATTEMPTS) {
      try {
        const response = await httpPollFollowUpOperations(base62Id, userId);
        const status = response.data.data.followup_operations_status;
        
        if (status === 'Ready' || status === 'Edited') {
          setLoadingMessage('Loading follow-up operations...');
          // Set approved to true by default for all operations
          const operations = response.data.data.followup_operations.operations.map(
            (operation: FollowUpOperation) => ({
              ...operation,
              approved: true
            })
          );
          return operations;
        } else if (status === 'Failed') {
          throw new Error('Follow-up operations generation failed');
        } else if (status === 'No-op') {
          // If No-op status is detected, automatically initiate a new request
          setLoadingMessage('No operations available. Requesting new operations...');
          console.log('Detected No-op status, initiating new follow-up operations request');
          
          try {
            // Request new follow-up operations
            await httpInitiateFollowUpOperations(base62Id, userId);
            setLoadingMessage('New follow-up operations requested. Waiting for processing...');
            
            // Reset attempt counter to allow sufficient time for the new request
            attempts = 0;
          } catch (initError) {
            console.error('Error initiating new follow-up operations:', initError);
            throw new Error('Failed to request new follow-up operations');
          }
        } else if (status === 'Queued') {
          setLoadingMessage('Follow-up operations generation is queued. Waiting for processing to begin...');
        } else if (status === 'Processing') {
          setLoadingMessage('Follow-up operations generation is in progress...');
        }
        // Continue polling for Queued or Processing states
      } catch (pollError) {
        console.error('Polling error:', pollError);
        // Continue polling even if there's an error
      }
      
      attempts++;
      await new Promise(resolve => setTimeout(resolve, POLL_INTERVAL));
    }
    
    throw new Error('Timed out waiting for follow-up operations');
  };

  const handleNext = async () => {
    try {
      // Check if there are any unanswered questions
      if (activeStep === 0) {
        // Simply move to step 2 without submitting anything
        setActiveStep(1);
      } else if (activeStep === 1) {
        // Submit all questions with follow-up details
        setLoading(true);
        setLoadingMessage('Submitting your details...');
        setError(null);
        
        console.log('Step 2: Submitting questions with follow-up answers:', questions);
        
        // Check if there are any follow-up answers
        const hasFollowUpAnswers = questions.some(question => 
          question.answer_options.some(option => 
            option.follow_up_request && option.follow_up_request.user_answer
          )
        );
        
        console.log('Has follow-up answers:', hasFollowUpAnswers);
        
        // Submit all questions and follow-up details to the server
        await httpSubmitImprovementAnswers(base62Id, userId, {
          questions: questions
        });
        
        // Verify that our data was saved correctly by reading it back
        try {
          const verifyResponse = await httpPollImprovements(base62Id, userId);
          console.log('Verification - saved improvement answers:', verifyResponse.data.data.improvement_answers);
          
          // Check if follow-up answers were saved correctly
          const savedQuestions = verifyResponse.data.data.improvement_answers?.questions || [];
          const followUpSaved = savedQuestions.some((question: ImprovementQuestion) => 
            question.answer_options.some((option) => 
              option.follow_up_request && option.follow_up_request.user_answer
            )
          );
          
          console.log('Follow-up details saved correctly:', followUpSaved);
          
          if (!followUpSaved && hasFollowUpAnswers) {
            console.warn('Follow-up details were not saved correctly, retrying submission');
            // Try submitting again if verification failed
            await httpSubmitImprovementAnswers(base62Id, userId, {
              questions: questions
            });
            // Verify again
            const retryVerifyResponse = await httpPollImprovements(base62Id, userId);
            console.log('Retry verification - saved improvement answers:', retryVerifyResponse.data.data.improvement_answers);
          }
        } catch (verifyErr) {
          console.error('Error verifying saved data:', verifyErr);
        }
        
        // Now initiate follow-up operations generation
        setLoadingMessage('Generating follow-up operations...');
        await httpInitiateFollowUpOperations(base62Id, userId);
        
        try {
          // Poll for follow-up operations
          const operationsData = await pollFollowUpOperationsUntilReady();
          console.log('Received follow-up operations:', operationsData);
          setOperations(operationsData);
          setActiveStep(2);
        } catch (err) {
          console.error('Follow-up operations error:', err);
          if (err instanceof Error && err.message === 'Timed out waiting for follow-up operations') {
            setError('The follow-up operations are taking longer than expected. You can try again or come back later.');
          } else if (err instanceof Error && err.message === 'Follow-up operations generation failed') {
            setError('We encountered an issue generating follow-up operations. Please try again.');
          } else {
            setError('Unable to load follow-up operations. Please try again later.');
          }
        }
        
        setLoading(false);
      } else if (activeStep === 2) {
        // Submit follow-up operations and move to next step
        setLoading(true);
        setLoadingMessage('Submitting your selections...');
        setError(null);
        
        console.log('Step 3: Submitting approved operations:', operations);
        
        await httpSubmitFollowUpOperations(base62Id, userId, {
          operations: operations
        });
        
        // Apply improvements
        setLoadingMessage('Applying improvements to your resume...');
        await httpApplyFollowUpOperations(base62Id, userId);
        
        // Wait for a moment to ensure the improvements are applied
        await new Promise(resolve => setTimeout(resolve, 2000));
        
        // Apply improvements and navigate to updated resume
        setLoadingMessage('Finalizing your improved resume...');
        setError(null);
        
        try {
          // Get the current tailored resume to check if it has a previous version
          const tailoredResponse = await httpOnGetTailored(base62Id, userId) as { data: { previous_version_id: string | null } };
          const shouldInsertNew = !tailoredResponse.data.previous_version_id;
          
          // Apply the follow-up operations
          const applyResponse = await httpApplyFollowUpOperations(base62Id, userId, shouldInsertNew);
          const newBase62Id = applyResponse.data.data.base62_id;
          
          // Poll for completion and navigate
          setLoadingMessage('Preparing your new resume...');
          
          let attempts = 0;
          const MAX_ATTEMPTS = 15;
          
          const detailedStatusContext = {
            status: '',
            statusChangedCounter: 0,
            detailedStatusCallback: (status: string, counter: number) => {
              // Optional: You can use this callback to update UI with detailed status
            }
          };

          while (attempts < MAX_ATTEMPTS) {
            try {
              const response = await httpPollTailoredExtracted(newBase62Id, userId, POLL_INTERVAL, detailedStatusContext) as ResumeDetailsApiResponse;
              if (response?.data?.status === 'Ready' as Status) {
                onClose();
                navigate(`/app/${params.resumeB62Id}/job/${params.jobB62Id}/tailored-resume/${newBase62Id}`);
                break;
              }
            } catch (pollError) {
              console.error('Polling error:', pollError);
            }
            attempts++;
            await new Promise(resolve => setTimeout(resolve, POLL_INTERVAL));
          }
          
          if (attempts >= MAX_ATTEMPTS) {
            throw new Error('Timed out waiting for the new resume');
          }
        } catch (err) {
          console.error('Error applying improvements:', err);
          setError('Error applying improvements. Your changes have been saved, but we couldn\'t navigate to the new resume.');
          setLoading(false);
        }
      }
    } catch (err) {
      console.error('Error in handleNext:', err);
      setError('An error occurred. Please try again later.');
      setLoading(false);
    }
  };

  const renderQuestions = () => (
    <Box>
      {questions.map((question, qIndex) => (
        <Box key={qIndex} mb={3}>
          <Typography variant="subtitle1" gutterBottom>
            {question.question}
          </Typography>
          <FormControl component="fieldset">
            {question.answer_type === 'yes_no' ? (
              <RadioGroup
                value={question.user_selections?.[0] || 'No'}
                onChange={(e) => handleQuestionAnswer(qIndex, e.target.value)}
              >
                {question.answer_options.map((option, oIndex) => (
                  <FormControlLabel
                    key={oIndex}
                    value={option.answer_option}
                    control={<Radio />}
                    label={option.answer_option}
                  />
                ))}
              </RadioGroup>
            ) : (
              <Box>
                {question.answer_options.map((option, oIndex) => (
                  <FormControlLabel
                    key={oIndex}
                    control={
                      <Checkbox
                        checked={question.user_selections?.includes(option.answer_option) || false}
                        onChange={(e) => {
                          const currentSelections = question.user_selections || [];
                          const newSelections = e.target.checked
                            ? [...currentSelections, option.answer_option]
                            : currentSelections.filter(s => s !== option.answer_option);
                          handleQuestionAnswer(qIndex, newSelections);
                        }}
                      />
                    }
                    label={option.answer_option}
                  />
                ))}
              </Box>
            )}
          </FormControl>
        </Box>
      ))}
    </Box>
  );

  const renderFollowUps = () => (
    <Box>
      {questions.map((question, qIndex) => (
        question.answer_options.map((option, oIndex) => {
          if (
            question.user_selections?.includes(option.answer_option) &&
            option.follow_up_request
          ) {
            const hasAnswer = option.follow_up_request.user_answer && option.follow_up_request.user_answer.trim() !== '';
            
            return (
              <Box key={`${qIndex}-${oIndex}`} mb={3}>
                <Typography variant="subtitle1" gutterBottom>
                  {option.follow_up_request.request}
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  value={option.follow_up_request.user_answer || ''}
                  onChange={(e) => handleFollowUpAnswer(qIndex, oIndex, e.target.value)}
                  sx={{ 
                    '& .MuiOutlinedInput-root': {
                      borderColor: hasAnswer ? 'success.main' : 'inherit',
                    }
                  }}
                />
                {hasAnswer && (
                  <Typography 
                    variant="caption" 
                    color="success.main" 
                    sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
                  >
                    Details saved
                  </Typography>
                )}
              </Box>
            );
          }
          return null;
        })
      ))}
      
      <Box sx={{ mt: 4, px: 2, py: 2, bgcolor: 'background.paper', borderRadius: 1, border: '1px solid', borderColor: 'divider' }}>
        <Typography variant="body2" color="text.secondary">
          Please provide details about your experience with data infrastructure. This information will help us generate more 
          tailored improvements for your resume. Click "Generate Improvements" when you've finished adding your details.
        </Typography>
      </Box>
    </Box>
  );

  const renderOperations = () => (
    <Box>
      {operations.map((operation, index) => (
        <Paper key={index} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Proposed Improvement {index + 1}
          </Typography>
          
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Context:
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {operation.operation_context}
            </Typography>

            <Typography variant="subtitle1" color="primary" gutterBottom>
              Proposed Change:
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {operation.proposed_change}
            </Typography>

            <Typography variant="subtitle1" color="primary" gutterBottom>
              Justification:
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {operation.operation_justification}
            </Typography>

            {operation.follow_up_request && (
              <>
                <Typography variant="subtitle1" color="primary" gutterBottom>
                  Your Response:
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  {operation.follow_up_request.user_answer}
                </Typography>
              </>
            )}
          </Box>

          <FormControlLabel
            control={
              <Checkbox
                checked={operation.approved === undefined ? true : operation.approved}
                onChange={(e) => handleOperationApproval(index, e.target.checked)}
              />
            }
            label={
              <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                Apply this improvement
              </Typography>
            }
          />
        </Paper>
      ))}
    </Box>
  );

  return (
    <Dialog 
      open={open}
      maxWidth="md" 
      fullWidth
    >
      <DialogTitle>Improve Your Resume</DialogTitle>
      <DialogContent>
        {error && (
          <Typography color="error" gutterBottom>
            {error}
          </Typography>
        )}
        
        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {loading ? (
          <Box display="flex" flexDirection="column" alignItems="center" p={4} gap={3}>
            <CircularProgress />
            <Typography variant="body1" textAlign="center" color="text.secondary">
              {loadingMessage || (
                activeStep === 0 
                  ? "We're analyzing your resume to identify areas for improvement. This will help us ask targeted questions to enhance your resume's match with this job opportunity."
                  : activeStep === 1 
                    ? "We're processing your answers to prepare tailored improvements for your resume."
                    : "We're applying your approved improvements to create an enhanced version of your resume. This may take a moment."
              )}
            </Typography>
          </Box>
        ) : error ? (
          <Box display="flex" flexDirection="column" alignItems="center" p={4} gap={3}>
            <Typography variant="body1" color="error" textAlign="center">
              {error}
            </Typography>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => {
                setError(null);
                if (activeStep === 0) {
                  checkCurrentState();
                }
              }}
            >
              Try Again
            </Button>
          </Box>
        ) : (
          <Box>
            {activeStep === 0 && renderQuestions()}
            {activeStep === 1 && renderFollowUps()}
            {activeStep === 2 && renderOperations()}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={onClose}
          disabled={loading}
        >
          {activeStep === steps.length - 1 ? 'Close' : 'Cancel'}
        </Button>
        {activeStep > 0 && !loading && !error && (
          <Button 
            onClick={handlePrevious}
            variant="outlined"
            sx={{ mr: 1 }}
          >
            Previous
          </Button>
        )}
        {!loading && !error && (
          <Button
            onClick={handleNext}
            variant="contained"
            disabled={loading || (activeStep === 0 && !hasChangedFromDefaults())}
          >
            {activeStep === 0 
              ? 'Continue to Details' 
              : activeStep === 1 
                ? 'Generate Improvements'
                : 'Apply Changes'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ImprovementDialog; 