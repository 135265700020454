import axios from 'axios';
import { awsOnGetSession, checkSessionExpiration, refreshSession, shouldRedirectToLogin, getStoredTokens } from '../Aws/Session';
import { cleanupLegacyStorage } from '../Aws/SessionUtils';
import { onAlertShowAction } from '../../Redux/Alert/Actions';
import { store } from '../../Redux/Store';
import { onCleanPersistRootAction } from '../../Redux/Root/Actions';
import UserPool from '../../Configs/UserPool';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

const authHeaderName = 'Authorization';
const CLIENT_ID = process.env.REACT_APP_USER_POOL_CLIENT_ID || '';
const STORAGE_KEY_PREFIX = 'CognitoIdentityServiceProvider';

if (!CLIENT_ID) {
    console.error('❌ REACT_APP_USER_POOL_CLIENT_ID is not set in environment variables');
}

// Helper to ensure consistent URL handling
const normalizeBaseUrl = (url: string | undefined) => {
    if (!url) return '';
    return url.endsWith('/') ? url.slice(0, -1) : url;
};

// Global redirect lock to prevent multiple redirects
let isRedirectingToLogin = false;
let redirectLockTime = 0;
const REDIRECT_LOCK_DURATION = 5000; // 5 seconds lock

// Session beacon to centralize auth state
let sessionBeaconPromise: Promise<{accessToken: string}> | null = null;
let lastBeaconTime = 0;
const BEACON_THROTTLE = 2000; // Only check session every 2 seconds max

// Add function to handle redirects consistently with global lock
const redirectToLogin = () => {
    const currentTime = Date.now();
    const currentPath = window.location.pathname;
    
    // Skip if already on root path
    if (currentPath === '/') {
        return;
    }
    
    // Check if redirect is allowed by the Session module
    if (!shouldRedirectToLogin()) {
        return;
    }
    
    // Check global redirect lock
    if (isRedirectingToLogin) {
        console.log('⚠️ Redirect already in progress, skipping additional redirect');
        return;
    }
    
    // Check if we're still in the lock period
    if (currentTime - redirectLockTime < REDIRECT_LOCK_DURATION) {
        console.log('⚠️ Redirect locked, skipping additional redirect');
        return;
    }
    
    // Set the global redirect lock
    isRedirectingToLogin = true;
    redirectLockTime = currentTime;
    
    console.log('🔄 Redirecting to root path...');
    
    // Clear session data to ensure user is logged out
    try {
        // Clear Cognito session data
        const userId = localStorage.getItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.LastAuthUser`);
        if (userId) {
            localStorage.removeItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${userId}.accessToken`);
            localStorage.removeItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${userId}.idToken`);
            localStorage.removeItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${userId}.refreshToken`);
        }
        localStorage.removeItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.LastAuthUser`);
        
        // Run cleanup to ensure all auth data is removed
        cleanupLegacyStorage(localStorage.setItem.bind(localStorage));
        
        console.log('✅ Session data cleared');
        
        // Force reload to root path to ensure clean state
        window.location.replace('/');
        
        // Release the lock after navigation starts
        setTimeout(() => {
            isRedirectingToLogin = false;
        }, 1000);
    } catch (error) {
        console.error('❌ Error clearing session data:', error);
        isRedirectingToLogin = false;
    }
};

const verifySession = async (): Promise<{accessToken: string}> => {
    const currentTime = Date.now();
    
    // If we're already redirecting, don't try to verify session
    if (isRedirectingToLogin) {
        return Promise.reject(new Error('Redirect to login in progress'));
    }
    
    // Return existing promise if we have one and it's recent
    if (sessionBeaconPromise && (currentTime - lastBeaconTime) < BEACON_THROTTLE) {
        return sessionBeaconPromise;
    }
    
    // Create new beacon promise
    sessionBeaconPromise = new Promise(async (resolve, reject) => {
        try {
            // Check for any stored tokens first before proceeding
            const storedTokens = getStoredTokens();
            if (!storedTokens) {
                console.log('❌ No stored tokens found, forcing navigation to root');
                store.dispatch(onCleanPersistRootAction());
                window.location.href = '/';
                reject(new Error('No stored tokens'));
                return;
            }

            // console.log('🔍 Verifying session...');
            const session = await awsOnGetSession();
            
            if (!session?.accessToken) {
                console.log('❌ No valid session, forcing navigation to root');
                store.dispatch(onCleanPersistRootAction());
                // Force immediate navigation without any checks
                window.location.href = '/';
                reject(new Error('No valid session'));
                return;
            }

            // First check for refresh token in localStorage
            const userId = localStorage.getItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.LastAuthUser`);
            if (!userId) {
                console.log('❌ No user ID found, forcing navigation to root');
                store.dispatch(onCleanPersistRootAction());
                // Force immediate navigation without any checks
                window.location.href = '/';
                reject(new Error('No user ID available'));
                return;
            }

            // Check for refresh token in localStorage
            let hasRefreshToken = false;
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                if (key && key.startsWith(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}`) && 
                    key.includes(userId) && key.endsWith('.refreshToken')) {
                    const refreshToken = localStorage.getItem(key);
                    if (refreshToken) {
                        hasRefreshToken = true;
                        break;
                    }
                }
            }

            if (!hasRefreshToken) {
                console.log('❌ No refresh token found, forcing navigation to root');
                store.dispatch(onCleanPersistRootAction());
                // Force immediate navigation without any checks
                window.location.href = '/';
                reject(new Error('No refresh token available'));
                return;
            }

            // Only proceed with Cognito session check if we have a refresh token
            try {
                const user = UserPool.getCurrentUser();
                if (!user) {
                    throw new Error('No current user');
                }
                
                // Get current session
                const currentSession = await new Promise<CognitoUserSession>((resolve, reject) => {
                    user.getSession((err: any, session: CognitoUserSession) => {
                        if (err) reject(err);
                        else resolve(session);
                    });
                });
            } catch (error) {
                console.log('❌ Failed to verify session:', error);
                store.dispatch(onAlertShowAction({
                    type: 'error',
                    message: 'Your login is no longer valid',
                    duration: 0,
                    keep: true
                }));
                store.dispatch(onCleanPersistRootAction());
                redirectToLogin();
                reject(error);
                return;
            }

            // Check if we need to refresh
            const needsRefresh = await checkSessionExpiration();
            if (needsRefresh) {
                console.log('🔄 Session expired - refreshing tokens');
                try {
                    const user = UserPool.getCurrentUser();
                    if (!user) {
                        throw new Error('No current user');
                    }
                    
                    // Get current session to check for refresh token
                    const currentSession = await new Promise<CognitoUserSession>((resolve, reject) => {
                        user.getSession((err: any, session: CognitoUserSession) => {
                            if (err) reject(err);
                            else resolve(session);
                        });
                    });
                    
                    // Check if refresh token exists
                    if (!currentSession.getRefreshToken()) {
                        console.log('❌ No refresh token available');
                        // Show dialog and clean up session
                        store.dispatch(onAlertShowAction({
                            type: 'error',
                            message: 'Your login is no longer valid',
                            duration: 0,
                            keep: true
                        }));
                        store.dispatch(onCleanPersistRootAction());
                        redirectToLogin();
                        reject(new Error('No refresh token available'));
                        return;
                    }

                    const newTokens = await refreshSession();
                    if (!newTokens?.accessToken) {
                        console.log('❌ Session refresh failed - no token returned');
                        reject(new Error('Session refresh failed'));
                        return;
                    }
                    resolve({ accessToken: newTokens.accessToken });
                } catch (refreshError) {
                    console.error('❌ Session refresh failed:', refreshError);
                    reject(refreshError);
                }
                return;
            }

            // console.log('✅ Session verified');
            resolve({ accessToken: session.accessToken });
        } catch (error) {
            console.error('❌ Session verification failed:', error);
            reject(error);
        }
    });
    
    lastBeaconTime = currentTime;
    return sessionBeaconPromise;
};

export const ApiInstance = axios.create({
    baseURL: normalizeBaseUrl(process.env.REACT_APP_API_BASE_URL)
});

export const ApiInstanceNoAuth = axios.create({
    baseURL: normalizeBaseUrl(process.env.REACT_APP_PUBLIC_API_BASE_URL)
});

// Add request interceptor that uses the session beacon
ApiInstance.interceptors.request.use(async (config) => {
    // Skip auth handling for auth endpoints
    if (config.url?.includes('/auth/') || config.url?.includes('/login')) {
        return config;
    }
    
    // If we're already redirecting, reject the request immediately
    if (isRedirectingToLogin) {
        return Promise.reject(new Error('Authentication redirect in progress'));
    }

    // Check for stored tokens first
    const storedTokens = getStoredTokens();
    if (!storedTokens) {
        console.log('❌ No stored tokens found in interceptor, forcing navigation');
        store.dispatch(onCleanPersistRootAction());
        window.location.href = '/';
        return Promise.reject(new Error('No stored tokens'));
    }

    try {
        // Wait for session verification and get token
        const { accessToken } = await verifySession();
        config.headers.Authorization = `Bearer ${accessToken}`;
        return config;
    } catch (error) {
        // If session verification fails, redirect and reject the request
        // Note: redirectToLogin is already called in verifySession if needed
        return Promise.reject(error);
    }
});

// Simplified response interceptor that only logs errors
ApiInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        // Skip auth handling for auth endpoints
        if (error.config?.url?.includes('/auth/') || error.config?.url?.includes('/login')) {
            return Promise.reject(error);
        }

        if (error.response?.status === 401 && !error.config._retry) {
            // Clear the session beacon to force a fresh check
            sessionBeaconPromise = null;
            console.error('❌ Authentication error:', error);
            
            // Try to refresh the token and retry the request
            try {
                error.config._retry = true; // Mark this request as retried
                console.log('🔄 Received 401 error - attempting to refresh token');
                
                const newTokens = await refreshSession();
                if (newTokens?.accessToken) {
                    // Update the failed request with new token and retry
                    error.config.headers.Authorization = `Bearer ${newTokens.accessToken}`;
                    return ApiInstance(error.config);
                }
            } catch (refreshError) {
                console.error('❌ Token refresh failed:', refreshError);
                // Only redirect if refresh fails and we're not already redirecting
                if (!isRedirectingToLogin) {
                    console.log('🔄 Token refresh failed - redirecting to login');
                    redirectToLogin();
                }
            }
        }
        
        return Promise.reject(error);
    }
);


