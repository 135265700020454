import UserPool from '../../Configs/UserPool';
import { CognitoUserSession, CognitoRefreshToken } from 'amazon-cognito-identity-js';

// These would be imported from Session.ts
export interface TokenInfo {
  accessToken: string;
  idToken: string;
  refreshToken?: string;
  expiresAt?: number;
}

// Constants that would be passed from Session.ts when calling these functions
export const STORAGE_KEY_PREFIX = 'CognitoIdentityServiceProvider';
export const CLIENT_ID = process.env.REACT_APP_USER_POOL_CLIENT_ID || '';

// Utility function to decode JWT and get expiration
export const getTokenExpiration = (token: string): number | null => {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        const payload = JSON.parse(jsonPayload);
        return payload.exp;
    } catch (error) {
        console.error('Error decoding token:', error);
        return null;
    }
};

// Add a debug function to log the current state of Cognito tokens
export const debugCognitoTokens = () => {
    try {
        console.log('🔍 Debugging Cognito tokens in localStorage:');
        console.log(`CLIENT_ID: ${CLIENT_ID}`);
        
        // Get all keys related to Cognito
        const allKeys = Object.keys(localStorage);
        const cognitoKeys = allKeys.filter(key => key.startsWith(STORAGE_KEY_PREFIX));
        
        if (cognitoKeys.length === 0) {
            console.log('No Cognito tokens found in localStorage');
            return;
        }
        
        // Group keys by user ID
        const keysByUser: Record<string, string[]> = {};
        cognitoKeys.forEach(key => {
            const parts = key.split('.');
            if (parts.length >= 3) {
                const clientId = parts[1];
                const userId = parts[2];
                const userKey = `${clientId}:${userId}`;
                
                if (!keysByUser[userKey]) {
                    keysByUser[userKey] = [];
                }
                keysByUser[userKey].push(key);
            }
        });
        
        // Log keys by user
        Object.entries(keysByUser).forEach(([userKey, keys]) => {
            const [clientId, userId] = userKey.split(':');
            console.log(`\nUser: ${userId} (Client ID: ${clientId})`);
            
            // Check for token types
            const hasAccessToken = keys.some(k => k.endsWith('.accessToken'));
            const hasIdToken = keys.some(k => k.endsWith('.idToken'));
            const hasRefreshToken = keys.some(k => k.endsWith('.refreshToken'));
            
            console.log(`Access Token: ${hasAccessToken ? '✅' : '❌'}`);
            console.log(`ID Token: ${hasIdToken ? '✅' : '❌'}`);
            console.log(`Refresh Token: ${hasRefreshToken ? '✅' : '❌'}`);
            
            // Log all keys for this user
            console.log('Keys:');
            keys.forEach(key => {
                const value = localStorage.getItem(key);
                const valuePreview = value ? `${value.substring(0, 10)}...` : 'null';
                console.log(`- ${key}: ${valuePreview}`);
            });
        });
    } catch (error) {
        console.error('❌ Error debugging Cognito tokens:', error);
    }
};

// Add a test function to immediately refresh the token
export const testRefreshFlow = async (getStoredTokens: Function, safeSetItem: Function): Promise<boolean> => {
    console.log('\n');
    console.log('🧪 ===== REFRESH TOKEN TEST STARTED =====');
    console.log('🧪 Testing refresh token flow...');
    try {
        // Get current tokens
        const tokens = getStoredTokens();
        if (!tokens || !tokens.userId) {
            console.error('❌ No tokens found for refresh test');
            console.log('🧪 ===== REFRESH TOKEN TEST FAILED =====');
            console.log('\n');
            return false;
        }

        // Get the refresh token
        const storedRefreshToken = localStorage.getItem(
            `${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${tokens.userId}.refreshToken`
        );
        
        if (!storedRefreshToken) {
            console.error('❌ No refresh token found for test');
            console.log('🧪 ===== REFRESH TOKEN TEST FAILED =====');
            console.log('\n');
            return false;
        }
        
        console.log('🔄 Found refresh token, attempting immediate refresh to verify flow...');
        
        // Force a token refresh
        const user = UserPool.getCurrentUser();
        if (!user) {
            console.error('❌ No current user found');
            console.log('🧪 ===== REFRESH TOKEN TEST FAILED =====');
            console.log('\n');
            return false;
        }
        
        // Create a refresh token object
        const refreshToken = new CognitoRefreshToken({ RefreshToken: storedRefreshToken });
        
        // Attempt to refresh the session
        return new Promise((resolve) => {
            user.refreshSession(refreshToken, (err: any, newSession: CognitoUserSession) => {
                if (err) {
                    console.error('❌ Refresh token test failed:', err);
                    console.log('🧪 ===== REFRESH TOKEN TEST FAILED =====');
                    console.log('\n');
                    resolve(false);
                    return;
                }
                
                // Get the new tokens
                const accessToken = newSession.getAccessToken().getJwtToken();
                const idToken = newSession.getIdToken().getJwtToken();
                const userId = newSession.getAccessToken().payload.username;
                
                // Store tokens in Cognito format
                safeSetItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${userId}.accessToken`, accessToken);
                safeSetItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${userId}.idToken`, idToken);
                
                console.log('✅ Refresh token test successful! Refresh flow is working correctly.');
                console.log('🧪 ===== REFRESH TOKEN TEST COMPLETED SUCCESSFULLY =====');
                console.log('\n');
                resolve(true);
            });
        });
    } catch (error) {
        console.error('❌ Error testing refresh flow:', error);
        console.log('🧪 ===== REFRESH TOKEN TEST FAILED =====');
        console.log('\n');
        return false;
    }
};

// For testing - get time until token expires
export const getTimeUntilExpiration = (getStoredTokens: Function): { 
    accessToken: { seconds: number, formatted: string, expiresAt: string }
} | null => {
    const tokens = getStoredTokens();
    if (!tokens) return null;

    const now = Math.floor(Date.now() / 1000);  // Current time in seconds
    
    // Get actual token expiration
    const accessExp = getTokenExpiration(tokens.accessToken);
    
    // Format time remaining
    const formatTimeRemaining = (secondsRemaining: number): string => {
        if (secondsRemaining <= 0) return 'Expired';
        const minutes = Math.floor(secondsRemaining / 60);
        const seconds = secondsRemaining % 60;
        return `${minutes}m ${seconds}s`;
    };
    
    // Format expiration date
    const formatExpirationDate = (expirationTimestamp: number): string => {
        const date = new Date(expirationTimestamp * 1000);
        return date.toLocaleTimeString();
    };

    return {
        accessToken: accessExp ? {
            seconds: accessExp - now,
            formatted: formatTimeRemaining(accessExp - now),
            expiresAt: formatExpirationDate(accessExp)
        } : { seconds: 0, formatted: 'Unknown', expiresAt: 'Unknown' }
    };
};

// Fix any refresh tokens with incorrect keys
export const fixRefreshTokenKeys = (safeSetItem: Function) => {
    try {
        // Look for keys with the pattern CognitoIdentityServiceProvider.undefined.*
        const allKeys = Object.keys(localStorage);
        const invalidTokenKeys = allKeys.filter(key => 
            key.startsWith(`${STORAGE_KEY_PREFIX}.undefined.`) && 
            (key.endsWith('.refreshToken') || 
             key.endsWith('.accessToken') || 
             key.endsWith('.idToken'))
        );
        
        if (invalidTokenKeys.length > 0) {
            console.log('🔧 Found invalid token keys, attempting to fix...', invalidTokenKeys);
            
            invalidTokenKeys.forEach(invalidKey => {
                // Extract the user ID from the key
                const parts = invalidKey.split('.');
                if (parts.length >= 4) {
                    const userId = parts[2]; // The user ID is the third part
                    const keyType = parts[3]; // The key type is the fourth part (refreshToken, accessToken, etc.)
                    const tokenValue = localStorage.getItem(invalidKey);
                    
                    if (tokenValue) {
                        // Store with the correct client ID
                        const correctKey = `${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${userId}.${keyType}`;
                        console.log(`🔄 Fixing token key: ${invalidKey} -> ${correctKey}`);
                        safeSetItem(correctKey, tokenValue);
                        
                        // Remove the invalid key
                        localStorage.removeItem(invalidKey);
                    }
                }
            });
            
            console.log('✅ Fixed invalid token keys');
        }
    } catch (error) {
        console.error('❌ Error fixing token keys:', error);
    }
};

// Function to clean up legacy storage formats
export function cleanupLegacyStorage(safeSetItem: Function) {
    console.log('🧹 Starting storage cleanup...');
    try {
        // First, clear any undefined user data
        const allKeys = Object.keys(localStorage);

        // Clean up deprecated tokenExpiration keys
        const deprecatedKeys = allKeys.filter(key => 
            key.startsWith(STORAGE_KEY_PREFIX) && 
            key.endsWith('.tokenExpiration')
        );
        
        if (deprecatedKeys.length > 0) {
            console.log('🧹 Cleaning up deprecated tokenExpiration keys:', deprecatedKeys);
            deprecatedKeys.forEach(key => {
                localStorage.removeItem(key);
            });
        }
        
        // Check for old Google auth format
        const persistRoot = localStorage.getItem('persist:root');
        if (persistRoot) {
            try {
                const persistData = JSON.parse(persistRoot);
                if (persistData.user) {
                    const userData = JSON.parse(persistData.user);
                    if (userData.userId && userData.accessToken) {
                        console.log('📦 Found old Google auth format, migrating...');
                        // Migrate to new format
                        safeSetItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.LastAuthUser`, userData.userId);
                        safeSetItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${userData.userId}.accessToken`, userData.accessToken);
                        safeSetItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${userData.userId}.idToken`, userData.accessToken);
                        safeSetItem('authProvider', 'google');
                    }
                }
            } catch (error) {
                console.error('❌ Error parsing persist:root:', error);
            }
        }

        allKeys.forEach(key => {
            if (key.includes('undefined') || 
                key.includes('null') || 
                localStorage.getItem(key) === 'undefined' || 
                localStorage.getItem(key) === 'null') {
                console.log('🗑️ Removing invalid key/value:', key);
                localStorage.removeItem(key);
            }
        });

        // Define safe keys that should never be cleaned up
        const safeKeys = ['hasSeenEditTooltip', 'authProvider', 'persist:root'];
        
        // Remove any old redirect prevention
        localStorage.removeItem('lastLoginRedirect');
        
        // Get current user ID from LastAuthUser
        const lastAuthUser = localStorage.getItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.LastAuthUser`);
        console.log('👤 Last auth user:', lastAuthUser);
        
        // If we have a valid last auth user, clean up everything except their data
        if (lastAuthUser && lastAuthUser !== 'undefined' && lastAuthUser !== 'null') {
            const validKeys = [
                `${STORAGE_KEY_PREFIX}.${CLIENT_ID}.LastAuthUser`,
                `${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${lastAuthUser}.accessToken`,
                `${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${lastAuthUser}.idToken`,
                `${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${lastAuthUser}.refreshToken`,
                ...safeKeys
            ];
            
            // Remove everything except valid keys
            allKeys.forEach(key => {
                // Special handling for old Google auth format
                if (key.startsWith('CognitoIdentityServiceProvider') && 
                    !key.includes(lastAuthUser) && 
                    !key.endsWith('LastAuthUser')) {
                    console.log('🗑️ Removing old auth key:', key);
                    localStorage.removeItem(key);
                    return;
                }

                if (!validKeys.includes(key)) {
                    console.log('🗑️ Removing key:', key);
                    localStorage.removeItem(key);
                } else {
                    // Verify the value is valid
                    const value = localStorage.getItem(key);
                    if (value === 'undefined' || value === 'null' || value === null) {
                        console.log('🗑️ Removing invalid value for key:', key);
                        localStorage.removeItem(key);
                    }
                }
            });
            
            // Verify we have valid tokens
            const accessToken = localStorage.getItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${lastAuthUser}.accessToken`);
            const idToken = localStorage.getItem(`${STORAGE_KEY_PREFIX}.${CLIENT_ID}.${lastAuthUser}.idToken`);
            
            if (!accessToken || !idToken || 
                accessToken === 'undefined' || accessToken === 'null' ||
                idToken === 'undefined' || idToken === 'null') {
                console.log('❌ Invalid token state detected, clearing all auth data');
                allKeys.forEach(key => {
                    if (key.startsWith(STORAGE_KEY_PREFIX) && !safeKeys.includes(key)) {
                        localStorage.removeItem(key);
                    }
                });
                return false;
            }
            
            console.log('✅ Storage cleanup completed successfully');
            return true;
        } else {
            // No valid last auth user, clean up all auth-related data
            console.log('❌ No valid last auth user, cleaning up all auth data');
            allKeys.forEach(key => {
                if (!safeKeys.includes(key) && 
                    (key.startsWith(STORAGE_KEY_PREFIX) || 
                     key === 'cognitoAccessToken' || 
                     key === 'userId' ||
                     key === 'LastAuthUser' ||
                     key === 'accessToken' ||
                     key === 'idToken')) {
                    console.log('🗑️ Removing auth key:', key);
                    localStorage.removeItem(key);
                }
            });
            
            console.log('✅ Complete storage cleanup finished');
            return false;
        }
    } catch (error) {
        console.error('❌ Error during storage cleanup:', error);
        console.error('Full error context:', error instanceof Error ? error.stack : error);
        return false;
    }
}

// Export a function to manually clean up all invalid token keys
export const cleanupAllTokenKeys = () => {
    console.log('🧹 Starting manual token key cleanup...');
    
    try {
        // First fix any invalid token keys
        fixRefreshTokenKeys((key: string, value: string) => {
            localStorage.setItem(key, value);
            return true;
        });
        
        // Then do a full legacy storage cleanup
        cleanupLegacyStorage((key: string, value: string) => {
            localStorage.setItem(key, value);
            return true;
        });
        
        console.log('✅ Manual token key cleanup completed');
        return true;
    } catch (error) {
        console.error('❌ Error during manual token key cleanup:', error);
        return false;
    }
};

// Make the cleanup function available globally for debugging
if (process.env.NODE_ENV === 'development') {
    // Ensure window.SessionTest exists
    if (!window.SessionTest) {
        window.SessionTest = {} as any;
    }
    
    // Add the cleanup function
    window.SessionTest.cleanupAllTokenKeys = cleanupAllTokenKeys;
}

// Make functions available globally for testing
declare global {
    interface Window {
        SessionTest: {
            getTimeUntilExpiration: () => { 
                accessToken: { seconds: number, formatted: string, expiresAt: string }
            } | null;
            checkAndRefreshTokens: () => Promise<any>;
            cleanupLegacyStorage: () => boolean;
            scheduleTokenRefresh: () => void;
            testRefreshFlow: () => Promise<boolean>;
            cleanupAllTokenKeys: () => boolean;
        }
    }
} 